<template>
  <div v-if="loadedTable" class="columns is-multiline">
    <div v-if="!show_data" class="column is-12">
      <div class="columns is-centered">
        <div class="column">
          <p class="has-text-centered">Termly mark weightings or grade thresholds are not available when the '<span
              class="has-text-weight-bold">Individual
            Marks</span>' or '<span class="has-text-weight-bold">None</span>' display type is selected.</p>
        </div>
      </div>
    </div>
    <div v-show="show_data" class="column is-12">
      <div class="columns is-multiline">
        <div class="column " :class="{'is-12':report.is_final===1,'is-6':report.is_final!==1}">
          <div class="columns is-multiline">
            <div class="column is-12">
              <div class="level is-clickable" @click="isTermlyExpanded=!isTermlyExpanded">
                <div class="level-left">
                  <div class="level-item">
                    <h3 class="is-size-4">Termly Mark Weightings:</h3>
                  </div>
                </div>
                <div class="level-right">
                  <div class="level-item">
                    <b-icon :icon="isTermlyExpanded?$tc('icons.menu-up'):$tc('icons.menu-down')"/>
                  </div>
                </div>
              </div>
              <p v-if="isTermlyExpanded" class="subtitle is-size-7">This is to group and weight the marks for the focus
                term you’re reporting
                on</p>
              <div v-show="isTermlyExpanded" class="mt-4">
                <b-field>
                  <div class="table-container">
                    <form class="has-text-centered" @submit.prevent="saveMarkGroups">
                      <table style="margin-right: auto;margin-left: auto" class="table  is-bordered is-striped"
                             @mouseenter="psUpdateMarkGroups" ref="scrollContainerMarkGroups">
                        <thead>
                        <tr class="table-input">
                          <th>Name</th>
                          <th>Weighting</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(group,index) in markGroupsEditHold" :key="group.id">
                          <td>
                            <b-field>
                              <b-input
                                  :disabled="!$store.getters['entities/user-permissions/find']('edit report-mark-groups') || (report.status_id <7 && !$store.getters['entities/user-permissions/find']('edit complete reports'))"
                                  required
                                  autocomplete="off"
                                  placeholder="Name"
                                  v-model="group.name"
                                  name="name"
                              />
                            </b-field>
                          </td>
                          <td>
                            <b-field :type="mark_groups_valid?null:'is-danger'">
                              <b-numberinput
                                  style="min-width: 4rem"
                                  :controls="false"

                                  :disabled="!$store.getters['entities/user-permissions/find']('edit report-mark-groups') || (report.status_id <7 && !$store.getters['entities/user-permissions/find']('edit complete reports'))"
                                  name="weighting"
                                  required v-model="group.weighting"></b-numberinput>

                            </b-field>
                          </td>
                          <td>
                            <b-field>
                              <b-checkbox v-model="group.has_weight_assessments" :true-value="1" :false-value="0">
                                Has weighted assessments
                              </b-checkbox>
                            </b-field>
                          </td>

                          <td>
                            <b-field
                                v-if="!!$store.getters['entities/user-permissions/find']('delete report-mark-groups') && report.status_id<7"
                                style="height: fit-content; margin: auto">
                              <b-tooltip
                                  label="Remove"
                                  size="is-small"
                                  type="is-danger"
                              >
                                <a
                                    class="card-header-icon has-text-danger px-0 py-0"
                                    @click.prevent.stop="startDeleteMarkGroup(group,index)"
                                >
                                  <b-icon :icon="$tc('icons.delete')"/>
                                </a>
                              </b-tooltip>
                            </b-field>
                          </td>

                        </tr>
                        </tbody>
                      </table>
                      <b-field position="is-centered" grouped>
                        <b-field position="is-centered"
                                 v-if="!!$store.getters['entities/user-permissions/find']('edit report-mark-groups') && report.status_id<7">
                          <b-button native-type="submit" type="is-primary">
                            Save
                          </b-button>
                        </b-field>
                        <b-field position="is-centered"
                                 v-if="!!$store.getters['entities/user-permissions/find']('create report-mark-groups') && report.status_id<7">
                          <b-button
                              type="is-link"
                              @click.prevent="startCreateMarkGroup()"
                          >Add Termly Mark Weighting
                          </b-button>
                        </b-field>
                      </b-field>
                    </form>
                  </div>
                </b-field>

              </div>
              <b-loading :active="loadingMarkGroups" :is-full-page="false" :can-cancel="false"></b-loading>
            </div>
            <div v-if="report.is_final===1" class="column is-12">
              <div class="level is-clickable" @click="isYearlyExpanded=!isYearlyExpanded">
                <div class="level-left">
                  <div class="level-item">
                    <h3 class="is-size-4">Yearly Mark Weightings:</h3>
                  </div>
                </div>
                <div class="level-right">
                  <div class="level-item">
                    <b-icon :icon="isYearlyExpanded?$tc('icons.menu-up'):$tc('icons.menu-down')"/>
                  </div>
                </div>
              </div>
              <p v-if="isYearlyExpanded" class="subtitle is-size-7">This is to group and weight the marks for the
                academic year</p>
              <div v-show="isYearlyExpanded" class="mt-4">
                <b-field>
                  <div class="table-container">
                    <form class="has-text-centered" @submit.prevent="saveFinalGroups">
                      <table style="margin-right: auto;margin-left: auto" class="table  is-bordered is-striped"
                             @mouseenter="psUpdateFinalGroups" ref="scrollContainerFinalGroups">
                        <thead>
                        <tr class="table-input">
                          <th>Name</th>
                          <th>Weighting</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(group,index) in finalGroupsEditHold" :key="group.id">
                          <td>
                            <b-field>
                              <b-select
                                  :disabled="!$store.getters['entities/user-permissions/find']('edit report-mark-groups') || (report.status_id <7 && !$store.getters['entities/user-permissions/find']('edit complete reports'))"
                                  @input="editFinalGroup($event,index)"
                                  required
                                  autocomplete="off"
                                  placeholder="Option"
                                  :value="group.name"
                                  name="name"
                              >
                                <option :key="option.name" v-for="option in finalGroupOptions" :value="option.name">
                                  {{ option.name }}
                                </option>
                              </b-select>
                            </b-field>
                          </td>
                          <td>
                            <b-field :type="final_groups_valid?null:'is-danger'">
                              <b-numberinput
                                  style="min-width: 4rem"

                                  :controls="false"
                                  :disabled="!$store.getters['entities/user-permissions/find']('edit report-mark-groups') || (report.status_id <7 && !$store.getters['entities/user-permissions/find']('edit complete reports'))"
                                  name="weighting"
                                  required v-model="group.weighting"></b-numberinput>

                            </b-field>
                          </td>


                          <td>
                            <b-field
                                v-if="!!$store.getters['entities/user-permissions/find']('delete report-mark-groups') && report.status_id<7"
                                style="height: fit-content; margin: auto">
                              <b-tooltip
                                  label="Remove"
                                  size="is-small"
                                  type="is-danger"
                              >
                                <a
                                    class="card-header-icon has-text-danger px-0 py-0"
                                    @click.prevent.stop="startDeleteFinalGroup(group,index)"
                                >
                                  <b-icon :icon="$tc('icons.delete')"/>
                                </a>
                              </b-tooltip>
                            </b-field>
                          </td>

                        </tr>
                        </tbody>
                      </table>
                      <b-field position="is-centered" grouped>
                        <b-field position="is-centered"
                                 v-if="!!$store.getters['entities/user-permissions/find']('edit report-mark-groups') && report.status_id<7">
                          <b-button native-type="submit" type="is-primary">
                            Save
                          </b-button>
                        </b-field>
                        <b-field position="is-centered"
                                 v-if="!!$store.getters['entities/user-permissions/find']('create report-mark-groups') && report.status_id<7">
                          <b-button
                              type="is-link"
                              @click.prevent="startCreateFinalGroup()"
                          >Add Yearly Mark Weighting
                          </b-button>
                        </b-field>
                      </b-field>
                    </form>
                  </div>
                </b-field>

              </div>
              <b-loading :active="loadingFinalGroups" :is-full-page="false" :can-cancel="false"></b-loading>
            </div>
          </div>
        </div>
        <div class="column" :class="{'is-12':report.is_final===1,'is-6':report.is_final!==1}">

          <div class="level is-clickable" @click="isThresholdsExpanded=!isThresholdsExpanded">
            <div class="level-left">
              <div class="level-item">
                <h3 class="is-size-4">Grade Thresholds:</h3>
              </div>
            </div>
            <div class="level-right">
              <div class="level-item">
                <b-icon :icon="isThresholdsExpanded?$tc('icons.menu-up'):$tc('icons.menu-down')"/>
              </div>
            </div>
          </div>
          <p v-if="isThresholdsExpanded" class="subtitle is-size-7">Threshold determining the minimum marks to be
            obtained for a particular grade in
            a subject</p>
          <div v-show="isThresholdsExpanded" class="mt-4">
            <b-field>
              <div class="table-container">
                <form class="has-text-centered"
                      @submit.prevent="saveGradeThresholds">
                  <table class="table is-bordered is-striped" style="margin-right: auto;margin-left: auto"
                         @mouseenter="psUpdateThresholds" ref="scrollContainerThresholds">
                    <thead>
                    <tr class="table-input">
                      <th>Symbol</th>
                      <th>Lowest %</th>
                      <th>Highest %</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(threshold,index) in gradeThresholdsEditHold" :key="threshold.id">
                      <td>
                        <b-field>
                          <b-input
                              :disabled="!$store.getters['entities/user-permissions/find']('edit report-subject-grade-thresholds')|| (report.status_id <7 && !$store.getters['entities/user-permissions/find']('edit complete reports'))"
                              required
                              autocomplete="off"
                              placeholder="Symbol"
                              v-model="threshold.symbol"
                              name="symbol"
                          />
                        </b-field>
                      </td>
                      <td>
                        <b-field>
                          <b-numberinput name="lowest"
                                         :disabled="!$store.getters['entities/user-permissions/find']('edit report-subject-grade-thresholds')|| (report.status_id <7 && !$store.getters['entities/user-permissions/find']('edit complete reports'))"
                                         step="0.5"
                                         style="min-width: 4rem"


                                         required v-model="threshold.lowest"></b-numberinput>

                        </b-field>
                      </td>
                      <td>
                        <b-field>
                          <b-numberinput name="highest"
                                         step="0.5"
                                         style="min-width: 4rem"

                                         :disabled="!$store.getters['entities/user-permissions/find']('edit report-subject-grade-thresholds')|| (report.status_id <7 && !$store.getters['entities/user-permissions/find']('edit complete reports'))"

                                         required v-model="threshold.highest"></b-numberinput>

                        </b-field>
                      </td>


                      <td>
                        <b-field
                            v-if="!!$store.getters['entities/user-permissions/find']('delete report-subject-grade-thresholds')&& report.status_id<7"
                            style="height: fit-content; margin: auto">
                          <b-tooltip
                              label="Remove"
                              size="is-small"
                              type="is-danger"
                          >
                            <a
                                class="card-header-icon has-text-danger px-0 py-0"
                                @click.prevent.stop="startDeleteGradeThreshold(threshold,index)"
                            >
                              <b-icon :icon="$tc('icons.delete')"/>
                            </a>
                          </b-tooltip>
                        </b-field>
                      </td>

                    </tr>
                    </tbody>
                  </table>
                  <b-field position="is-centered" grouped>
                    <b-field position="is-centered"
                             v-if="!!$store.getters['entities/user-permissions/find']('edit report-subject-grade-thresholds')&& report.status_id<7">
                      <b-button native-type="submit" type="is-primary">
                        Save
                      </b-button>
                    </b-field>
                    <b-field position="is-centered"
                             v-if="!!$store.getters['entities/user-permissions/find']('create report-subject-grade-thresholds') && report.status_id<7">
                      <b-button
                          type="is-link"
                          @click.prevent="startCreateGradeThreshold()"
                      >Add Grade
                      </b-button>
                    </b-field>
                  </b-field>
                </form>
              </div>
            </b-field>

          </div>
          <b-loading :active="loadingGradeThresholds" :is-full-page="false" :can-cancel="false"></b-loading>
        </div>
      </div>

    </div>
    <div class="column is-12">
      <div class="bar-container" @mouseenter="psUpdate" ref="scrollContainer">
        <div class="flippy">
          <div class="columns">
            <div class="column">
              <p class="has-text-centered  is-size-3">{{ $tc('Learner', 2) }}:</p>
              <b-table

                  class="specific-header-height"
                  :data="users"
                  :striped="true"
                  ref="nameTable"
                  :loading="loading"
                  v-if="loaded"
                  :hoverable="true"
                  :bordered="true"
              >
                <template #empty>
                  <div class="has-text-centered">No {{ $tc('Learner', 2) }}</div>
                </template>
                <b-table-column
                    centered

                    v-slot="props"
                    label="ID"
                    field="id"
                    sortable
                    width="40"
                    numeric
                >{{ props.row.id }}
                </b-table-column>

                <b-table-column
                    centered
                    cell-class="has-text-left"

                    v-slot="props"
                    sortable
                    field="last_name"
                    :label="`${$tc('Learner',1)} Surname`"
                >{{ props.row.last_name }}
                </b-table-column>
                <b-table-column
                    centered
                    cell-class="has-text-left"
                    v-slot="props"
                    sortable
                    field="first_name"
                    :label="`${$tc('Learner',1)} Name`"
                >{{ props.row.first_name }}
                </b-table-column>
                <b-table-column
                    centered
                    sortable
                    field="enrolment_status.name"
                    v-slot="props"
                    label="Status"
                >{{ props.row.enrolment_status.name }}
                </b-table-column>
                <b-table-column
                    centered
                    cell-class="py-0"
                    v-if="selected_subject.requires_educator_comment || selected_subject.requires_educator_checkpoints"
                    label="Comment/Checklist" v-slot="props">
                  <b-button :loading="loadingComments"
                            :disabled="!$store.getters['entities/user-permissions/find']('view report-subject-comments')"
                            @click="handleCommentButtonClick(props.row.id,userComment(props.row.id))"
                            type="is-primary">
                    {{ comment_user_ids.includes(props.row.id) ? 'View/Edit' : 'Create' }}
                  </b-button>
                </b-table-column>


              </b-table>

            </div>
            <template v-if="assessments.length>0">
              <div :key="term.id"
                   v-for="term in terms"
                   class="column">
                <p class="has-text-centered  is-size-3">{{ term.name }}:</p>

                <b-table
                    class="specific-header-height"
                    :data="orderedEnrolments"
                    :striped="true"
                    v-if="loadedEnrolmentsTable"

                    :loading="loading"
                    :hoverable="true"
                    :bordered="true"
                >

                  <template #empty>
                    <div class="has-text-centered">No Assessments</div>
                  </template>
                  <b-table-column centered
                                  :label="`${assessment.name} (${assessment.assessment_type})`"
                                  v-for="assessment in termOrderedAssessments[term.id].assessments"
                                  :key="assessment.id">
                    <template v-slot:header="{ column }">
                      <p class="mark-column-header">{{ column.label }}</p>
                      <b-field style="min-width: max-content" :grouped="assessment.type_id===4" v-if="show_data">
                        <b-field expanded>
                          <b-select expanded
                                    v-if="assessment.type_id !==4"
                                    :disabled="!$store.getters['entities/user-permissions/find']('assign assessment-to-mark-groups')||report.status_id>6"
                                    :value="assessment.markgroup_id"
                                    :loading="loadingAssessments.includes(assessment.id)"
                                    @input="assignMarkGroup($event,assessment.id,assessment.markgroup_id,assessment.type_id,null)"
                                    placeholder="Termly Mark Weightings">
                            <option :value="null">None</option>
                            <option v-for="group in mark_groups" :value="group.id"
                                    :key="group.id + 'assessment:'+assessment.id">{{ group.name }}
                            </option>
                          </b-select>
                          <b-select v-else expanded
                                    :disabled="!$store.getters['entities/user-permissions/find']('assign assessment-to-mark-groups')||report.status_id>6"
                                    :value="assessment.markgroup_id"
                                    :loading="loadingAssessments.includes(assessment.id)"
                                    @input="assignMarkGroup($event,assessment.id,assessment.markgroup_id,assessment.type_id,assessment.weighting)"
                                    placeholder="Termly Mark Weightings">
                            <option :value="null">None</option>
                            <option v-for="group in formal_mark_groups" :value="group.id"
                                    :key="group.id + 'assessment:'+assessment.id">{{ group.name }}
                            </option>
                          </b-select>
                        </b-field>

                        <b-field :ref="`weighted_assessment_${assessment.id}`"
                                 :type="(assessment.markgroup_id===null||assessment.weighting_valid)?null:'is-danger'"
                                 v-if="assessment.type_id===4"
                                 style="max-width: 90px">
                          <b-numberinput :disabled="!assessment.markgroup_id"
                                         @input="checkValid(assessment)"
                                         @blur="assignMarkGroup(assessment.markgroup_id,assessment.id,assessment.markgroup_id,assessment.type_id,assessment.weighting)"
                                         @keydown.enter.native="assignMarkGroup(assessment.markgroup_id,assessment.id,assessment.markgroup_id,assessment.type_id,assessment.weighting)"
                                         v-model="assessment.weighting" placeholder="Weight"
                                         :controls="false"></b-numberinput>
                        </b-field>
                      </b-field>
                    </template>
                    <template v-slot="props">
                    <span v-if="assessment.grades[props.row.id] !==false">{{
                        assessment.grades[props.row.id]
                      }}/{{
                        assessment.total_marks
                      }}</span>


                      <span v-else>Absent</span>
                    </template>


                  </b-table-column>

                  <b-table-column centered
                                  :label="`${markGroupTotal.name}`"
                                  v-for="(markGroupTotal,index) in termOrderedMarkGroups[term.id].groups"
                                  :key="markGroupTotal.id + 'term' + term.id + new Date().getTime()">
                    <template v-slot:header="{ column }">
                      <p class="mark-column-header">{{ column.label }}</p>
                    </template>
                    <template v-slot="props">
                    <span v-if="termOrderedMarkGroups[term.id].groups[index]">{{
                        markGroupTotal.weighted_marks[props.row.id]
                      }}</span></template>

                  </b-table-column>
                  <b-table-column centered
                                  :label="`${term.name} Total`"
                  >
                    <template v-slot:header="{ column }">
                      <p class="mark-column-header">{{ column.label }}</p>
                    </template>
                    <template v-slot="props">
                      <span>{{ termOrderedMarkGroups[term.id].total[props.row.id] }}</span></template>
                  </b-table-column>
                </b-table>
              </div>
            </template>
            <template v-if="report.is_final===1">
              <div class="column">
                <p class="has-text-centered  is-size-3">Final</p>

                <b-table
                    class="specific-header-height"
                    :data="orderedEnrolments"
                    :striped="true"
                    v-if="loadedEnrolmentsTable && (typeof finalTotals ==='object')"

                    :loading="loading"
                    :hoverable="true"
                    :bordered="true"
                >

                  <template #empty>
                    <div class="has-text-centered">No Assessments</div>
                  </template>
                  <b-table-column v-for="(item,index) of previousReportTotals"
                                  :key="index" centered
                                  :label="item.name"
                  >

                    <template v-slot="props">
                      <span>{{
                          typeof finalTotals[props.row.id].previous_report_totals[index].mark !== 'undefined' ? finalTotals[props.row.id].previous_report_totals[index].mark : 'Wait'
                        }}</span></template>
                  </b-table-column>
                  <b-table-column centered
                                  :label="`Overall`"
                  >

                    <template v-slot="props">
                      <span>{{
                          typeof finalTotals[props.row.id].final_display !== 'undefined' ? finalTotals[props.row.id].final_display : 'Wait'
                        }}</span></template>
                  </b-table-column>
                </b-table>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>

import {mapState} from "vuex";
import Term from "@/models/Term";
import Assessment from "@/models/Assessment";
import Grade from "@/models/Grade";
import Enrolment from "@/models/Enrolment";
import User from "@/models/User";
import ReportMarkGroup from "@/models/ReportMarkGroup";
import ReportSubjectGradeThreshold from "@/models/ReportSubjectGradeThreshold";
import ReportSubjectGradeTotals from "@/models/ReportSubjectGradeTotals";
import EnrolmentStatus from "@/models/EnrolmentStatus";
import ReportSubjectComment from "@/models/ReportSubjectComment";
import ReportSubjectCommentsForm from "@/components/reports/ReportSubjectCommentsForm";
import ReportSubjectAssessmentGrades from "@/models/ReportSubjectAssessmentGrades";
import ClassGroup from "@/models/ClassGroup";
import ReportFinalGroup from "@/models/ReportFinalGroup";
import ReportFinalGroupOption from "@/models/ReportFinalGroupOption";
import PerfectScrollbar from "perfect-scrollbar";
// import ReportSubjectAssessmentGrades from "@/models/ReportSubjectAssessmentGrades";

export default {
  name: "MarkGroups",
  components: {},
  data() {
    return {
      loadingTerm: null,
      loadedTable: true,
      loaded: false,
      loadedEnrolmentsTable: false,
      search: null,
      loadingAssessments: [],
      focusedInput: {assessment_id: null, user_id: null},
      expandedTerm: null,
      expandedParentTerm: null,
      year: this.selected_assessment ? new Date(this.selected_assessment.start).getFullYear() : new Date().getFullYear(),
      filter_by_date: false,
      loadingFilter: false,
      plansDataHold: null,
      meta: Object,
      page: 1,
      use_modal: false,
      limit: 20,
      loading: false,
      isThresholdsExpanded: false,
      isTermlyExpanded: true,
      isYearlyExpanded: false,
      loadingData: false,
      termsDataHold: null,
      term_id: null,
      focusedAssessment: this.selected_assessment,
      assessmentsDataHold: null,
      markGroupsDatahold: [],
      markGroupsEditHold: [],
      finalGroupsDatahold: [],
      finalGroupsEditHold: [],
      gradeThresholdsDatahold: [],
      gradeThresholdsEditHold: [],
      loadingGradeThresholds: false,
      loadingGroupOptions: false,
      sortField: null,
      sortOrder: null,
      loadingMarkGroups: false,
      loadingFinalGroups: false,
      searchHold: null,
      phaseSearchId: null,
      stageSearchId: null,
      loadingComments: false,
      groupOptionsDatahold: [],

    }
  },
  props: {
    report: {
      type: Object,
      required: true
    },
    selected_assessment: {
      type: Object,
      default() {
        return null
      }
    }, selected_subject: {
      type: Object,
      required: true
    },
    canDelete: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    headerText: {
      type: String,
      default() {
        return "Courses";
      },
    },
    createText: {
      type: String,
      default() {
        return "Course";
      },
    },
    tabEntity: {
      type: String,
      default() {
        return "Subjects";
      },
    },

  },
  methods: {
    psInit() {
      this.ps = new PerfectScrollbar(this.$refs.scrollContainer, {suppressScrollY: true})
    },
    psUpdate() {
      if (this.ps) {
        this.ps.update()
      }
    },
    psInitMarkGroups() {
      this.psMarkGroups = new PerfectScrollbar(this.$refs.scrollContainerMarkGroups, {suppressScrollY: true})
    },
    psUpdateMarkGroups() {
      if (this.psMarkGroups) {
        this.psMarkGroups.update()
      }
    },
    psInitThresholds() {
      this.psThresholds = new PerfectScrollbar(this.$refs.scrollContainerThresholds, {suppressScrollY: true})
    },
    psUpdateThresholds() {
      if (this.psThresholds) {
        this.psThresholds.update()
      }
    }, psInitFinalGroups() {
      this.psFinalGroups = new PerfectScrollbar(this.$refs.scrollContainerFinalGroups, {suppressScrollY: true})
    },
    psUpdateFinalGroups() {
      if (this.psFinalGroups) {
        this.psFinalGroups.update()
      }
    },
    getClassesGroups() {
      ClassGroup.FetchAll({page: 1, limit: 999}, {subject_id: this.selected_subject.id}).then(response => {
        if (response.response.data.data.length > 0) {
          let classGroupId = parseInt(this.$store.state.campus.selected_class_group_id !== null ? this.$store.state.campus.selected_class_group_id : response.response.data.data[0].id)
          if (this.$route.query.class_group_id) {
            classGroupId = parseInt(this.$route.query.class_group_id)
          }
          if (!response.response.data.data.some(group => group.id === classGroupId)) {
            classGroupId = response.response.data.data[0].id
          }

          this.setQuery({class_group_id: classGroupId})

          this.$store.dispatch('campus/selectClassGroup', classGroupId)
          ClassGroup.FetchById(classGroupId).then(() => {
            this.$store.state.campus.loadedClassGroups = true
          })
        } else {
          this.$store.dispatch('campus/selectClassGroup', null)
          this.$store.state.campus.loadedClassGroups = true
        }
      }).catch(err => {
        this.handleError(err)
      })
    },
    checkValid(assessment) {
      Assessment.update({where: assessment.id, data: {weighting: assessment.weighting}})
    },
    // isWeightValid(assessment){
    //   return Assessment.query().where('markgroup_id',assessment.markgroup_id).sum('weighting')===100
    //
    // },
    gradeEntityAbsent(assessment_id, user_id) {
      let grade = Grade.query().where('user_id', user_id).where('assessment_id', assessment_id).first()
      if (grade) {
        return grade.absent_reason_id
      }
      return null
    },

    handleCommentButtonClick(user_id, subjectComment) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          ...{
            userId: user_id,
            reportId: this.report.id,
            reportStatus: this.report.status_id < 7 ? 'open' : 'closed',
            edit: this.comment_user_ids.includes(user_id),
            inModal: true,
            reportSubject: this.selected_subject
          },
          ...(subjectComment !== null ? {reportComment: subjectComment} : {})
        },
        component: ReportSubjectCommentsForm,
        fullScreen: false,
        trapFocus: true,
        canCancel: ['outside', 'x']
      });
    },


    markTermTotal(user_id, term_id) {
      if (this.totals.length > 0) {
        if (!Array.isArray(ReportSubjectGradeTotals.query().where('term_id', term_id).first().markgroup_totals.marks)) {
          let total_mark = ReportSubjectGradeTotals.query().where('term_id', term_id).first().markgroup_totals.marks[user_id]
          if (typeof total_mark !== 'undefined') {
            return total_mark.total_display
          }
          return 'N/A'
        } else {
          return 'N/A'
        }
      }
      return 'N/A'
    },
    userComment(user_id) {
      if (ReportSubjectComment.query().where('user_id', user_id).exists()) {
        return ReportSubjectComment.query().where('user_id', user_id).first()
      }
      return null
    },
    getComments() {
      this.loadingComments = true
      ReportSubjectComment.FetchAll({
        page: 1,
        limit: 999
      }, {report_subject_id: this.selected_subject.report_subject_id}, [], this.report.id).catch(err => {
        this.handleError(err)

      }).finally(() => {
        this.loadingComments = false
      })
    },
    getTotals() {
      ReportSubjectGradeTotals.FetchTotals(this.report.id, this.selected_subject.id, this.$store.state.campus.selected_class_group_id).then(() => {
        this.$forceUpdate()
      })
    },
    markGroupTotals(term_id) {
      if (this.totals.length > 0) {
        let value = ReportSubjectGradeTotals.query().where('term_id', term_id).first().markgroups
        if (value !== null) {
          return value
        }
        return []
      }
      return []
    },
    markGroupTotalValue(user_id, marks) {
      let mark = marks.find(mark => mark.user_id === user_id)
      if (typeof mark !== 'undefined') {
        return mark.group_weighted_mark_display
      }
      return null
    },
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
    },


    assignMarkGroup(mark_group_id, assessment_id, former_mark_group_id, type_id, weighting) {
      let adjustedWeighting = type_id === 4 ? (weighting === null ? 0 : weighting) : null
      this.startLoading(assessment_id)
      if (mark_group_id === null) {
        if (former_mark_group_id) {
          return ReportMarkGroup.Detach(this.report.id, former_mark_group_id, assessment_id).then(() => {
            this.getTotals()

            this.getMarkGroups()
          }).catch(err => {
            this.handleError(err)
          }).finally(() => {
            this.stopLoading(assessment_id)
          })
        }
        return this.stopLoading(assessment_id)

      }
      if (former_mark_group_id !== null) {
        return ReportMarkGroup.Detach(this.report.id, former_mark_group_id, assessment_id).then(() => {
          ReportMarkGroup.Attach(this.report.id, mark_group_id, assessment_id, adjustedWeighting).then(() => {
            this.getTotals()
            this.getMarkGroups()
          }).catch(err => {
            this.handleError(err)
          }).finally(() => {
            this.stopLoading(assessment_id)
          })
        }).catch(err => {
          this.handleError(err)
        }).finally(() => {
          this.stopLoading(assessment_id)
        })
      } else {
        ReportMarkGroup.Attach(this.report.id, mark_group_id, assessment_id, adjustedWeighting).then(() => {
          this.getTotals()

          this.getMarkGroups()
        }).catch(err => {
          this.handleError(err)
        }).finally(() => {
          this.stopLoading(assessment_id)
        })
      }
    },
    saveGradeThresholds() {
      // let total = 0
      // this.grade.map(group => total += group.weighting)
      // if (total !== 100) {
      //   return this.$buefy.dialog.alert('The mark group weightings must add up to exactly 100!')
      // }
      this.loadingGradeThresholds = true

      let promises = []
      this.gradeThresholdsEditHold.map(threshold => {
        if (Object.prototype.hasOwnProperty.call(threshold, 'id')) {
          promises.push(ReportSubjectGradeThreshold.Update(threshold))
        } else {
          delete threshold.id
          ReportSubjectGradeThreshold.Store(threshold)
        }
      })

      Promise.all(promises).then(() => {
        this.getGradeThresholds().then(() => {
          this.$buefy.snackbar.open('Grade Thresholds updated!')

        })
      }).catch(err => {
        this.handleError(err)
      }).finally(() => {
        this.loadingGradeThresholds = false
      })
    },
    startCreateGradeThreshold() {
      this.gradeThresholdsEditHold.push({
        symbol: '',
        lowest: null,
        highest: null,
        report_id: this.report.id,
        subject_id: this.selected_subject.id
      })
    },
    startDeleteGradeThreshold(threshold, index) {
      if (!Object.prototype.hasOwnProperty.call(threshold, 'id')) {
        return this.gradeThresholdsEditHold.splice(index, 1)
      }
      this.$buefy.dialog.confirm({
        title: "Deleting Grade Threshold",
        message:
            "Are you sure you want to <b>delete</b> this grade threshold? This action cannot be undone.",
        confirmText: "Delete Grade Threshold",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteGradeThreshold(threshold, index),
      });
    },
    deleteGradeThreshold(threshold, index) {
      this.$store.dispatch("loader/show");
      ReportSubjectGradeThreshold.Delete(threshold).then(
          () => {
            this.gradeThresholdsEditHold.splice(index, 1)
            this.$buefy.snackbar.open('Grade Threshold deleted!')
          }
      ).catch(
          err => {
            this.handleError(err)
          }
      ).finally(() => {
        this.$store.dispatch("loader/hide");

      })

    },
    saveMarkGroups() {
      let total = 0
      this.markGroupsEditHold.map(group => total += group.weighting)
      if (total !== 100) {
        return this.$buefy.dialog.alert('The termly mark weightings weightings must add up to exactly 100!')
      }
      this.loadingMarkGroups = true

      let promises = []
      this.markGroupsEditHold.map(group => {
        if (Object.prototype.hasOwnProperty.call(group, 'id')) {
          promises.push(ReportMarkGroup.Update(group))
        } else {
          delete group.id
          ReportMarkGroup.Store(group)
        }
      })

      Promise.all(promises).then(() => {
        this.getTotals()
        this.getReportFinalGroupOptions()
        this.getMarkGroups().then(() => {
          this.$buefy.snackbar.open('Termly Mark Weightings updated!')

        })
      }).catch(err => {
        this.handleError(err)
      }).finally(() => {
        this.loadingMarkGroups = false
      })
    },
    editFinalGroup(groupName, index) {
      let editGroup = this.finalGroupsEditHold[index]
      let sourceGroup = ReportFinalGroupOption.query().where('name', groupName).first()
      editGroup.type_id = sourceGroup.type_id
      if (sourceGroup.type_id === 1) {
        delete editGroup.current_mark_group_id
        editGroup.previous_report_id = sourceGroup.report_id
        editGroup.previous_mark_group_id = sourceGroup.mark_group_id
      }
      if (sourceGroup.type_id === 2) {
        delete editGroup.current_mark_group_id
        editGroup.previous_report_id = sourceGroup.report_id
        delete editGroup.previous_mark_group_id
      }
      if (sourceGroup.type_id === 3) {
        editGroup.current_mark_group_id = sourceGroup.mark_group_id
        delete editGroup.previous_report_id
        delete editGroup.previous_mark_group_id
      }
      editGroup.name = sourceGroup.name

    },
    saveFinalGroups() {
      let total = 0
      this.finalGroupsEditHold.map(group => total += group.weighting)
      if (total !== 100) {
        return this.$buefy.dialog.alert('The group weightings must add up to exactly 100!')
      }
      this.loadingFinalGroups = true

      let promises = []
      this.finalGroupsEditHold.map(group => {
        if (Object.prototype.hasOwnProperty.call(group, 'id')) {
          if (group.previous_mark_group_id === null) {
            delete group.previous_mark_group_id
          }
          if (group.previous_report_id === null) {
            delete group.previous_report_id
          }
          if (group.current_mark_group_id === null) {
            delete group.current_mark_group_id
          }
          promises.push(ReportFinalGroup.Update(group))
        } else {
          delete group.id
          ReportFinalGroup.Store(group)
        }
      })

      Promise.all(promises).then(() => {
        this.getTotals()
        this.getFinalGroups().then(() => {
          this.$buefy.snackbar.open('Yearly Mark Weightings updated!')

        })
      }).catch(err => {
        this.handleError(err)
      }).finally(() => {
        this.loadingFinalGroups = false
      })
    },
    startCreateMarkGroup() {
      let hold = JSON.parse(JSON.stringify(this.markGroupsEditHold))

      hold.push({
        name: '',
        assessment_ids: [],
        weighting: 0,
        report_id: this.report.id,
        subject_id: this.selected_subject.id,
        class_group_id: this.$store.state.campus.selected_class_group_id,
        has_weight_assessments: 0
      })
      this.markGroupsEditHold = hold
    }, startCreateFinalGroup() {
      let hold = JSON.parse(JSON.stringify(this.finalGroupsEditHold))

      hold.push({
        name: '',
        weighting: 0,
        report_id: this.report.id,
        subject_id: this.selected_subject.id,
        class_group_id: this.$store.state.campus.selected_class_group_id,
        type_id: null
      })
      this.finalGroupsEditHold = hold
    },
    startDeleteMarkGroup(markGroup, index) {
      if (!Object.prototype.hasOwnProperty.call(markGroup, 'id')) {
        return this.markGroupsEditHold.splice(index, 1)
      }
      this.$buefy.dialog.confirm({
        title: "Deleting Termly Mark Weighting",
        message:
            "Are you sure you want to <b>delete</b> this termly mark weighting? This action cannot be undone.",
        confirmText: "Delete termly mark weighting",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteMarkGroup(markGroup, index),
      });
    },
    deleteMarkGroup(markGroup, index) {
      this.$store.dispatch("loader/show");
      ReportMarkGroup.Delete(markGroup).then(
          () => {
            this.markGroupsEditHold.splice(index, 1)
            this.$buefy.snackbar.open('Termly Mark Weighting deleted!')
          }
      ).catch(
          err => {
            this.handleError(err)
          }
      ).finally(() => {
        this.$store.dispatch("loader/hide");

      })

    }, startDeleteFinalGroup(finalGroup, index) {
      if (!Object.prototype.hasOwnProperty.call(finalGroup, 'id')) {
        return this.finalGroupsEditHold.splice(index, 1)
      }
      this.$buefy.dialog.confirm({
        title: "Deleting Yearly Mark Weighting",
        message:
            "Are you sure you want to <b>delete</b> this weighting? This action cannot be undone.",
        confirmText: "Delete Yearly Mark Weighting",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteFinalGroup(finalGroup, index),
      });
    },
    deleteFinalGroup(finalGroup, index) {
      this.$store.dispatch("loader/show");
      ReportFinalGroup.Delete(finalGroup).then(
          () => {
            this.finalGroupsEditHold.splice(index, 1)
            this.$buefy.snackbar.open('Yearly Mark Weighting deleted!')
          }
      ).catch(
          err => {
            this.handleError(err)
          }
      ).finally(() => {
        this.$store.dispatch("loader/hide");

      })

    },
    termAssessments(term_id) {
      return Assessment.query().where('term_id', term_id).where('class_group_id', this.selected_class_group_id).orderBy('start').get()
    },
    startLoading(assessment_id) {
      this.loadingAssessments.push(assessment_id)
    },
    stopLoading(assessment_id) {
      this.loadingAssessments = this.loadingAssessments.filter(assessment => {
        return assessment !== assessment_id
      })
    },

    gradeEntity(assessment_id, user_id) {
      let grade = Grade.query().where('user_id', user_id).where('assessment_id', assessment_id).first()
      if (grade) {
        return grade
      }
      return null
    }, gradeEntityValue(assessment_id, user_id) {
      let grade = Grade.query().where('user_id', user_id).where('assessment_id', assessment_id).first()
      if (grade) {
        return grade.total
      }
      return null
    },

    selectTerms(term_ids) {

      this.loading = true
      this.assessmentsDataHold = this.assessments
      Assessment.deleteAll()
      ReportSubjectAssessmentGrades.FetchAll(
          this.report.id,
          this.selected_subject.id,
          this.$store.state.campus.selected_class_group_id
      ).then(() => {
        let assessmentPromises = []

        term_ids.map(term => {
          assessmentPromises.push(Assessment.FetchAll({page: 1, limit: 999}, {
            term_id: term,
            subject_id: this.selected_subject.id, ...(this.$store.state.campus.selected_class_group_id
                ? {
                  class_group_id: this.$store.state.campus.selected_class_group_id,
                }
                : {})
          }))
        })
        Promise.all([assessmentPromises]).then(() => {

          User.deleteAll()
          this.getMarkGroups()
          if (this.report.is_final === 1) {
            this.getFinalGroups()
          }
          // return Enrolment.FetchEnrolmentsBySubjectId(this.selected_subject.id, ['user'], {
          //   page: 1,
          //   limit: 999
          // }, {
          //   year: this.report.year, ...(this.$store.state.campus.selected_class_group_id
          //       ? {
          //         class_group_id: this.$store.state.campus.selected_class_group_id,
          //       }
          //       : {})
          // }, 'create')
          User.FetchReportSubjectUsers({page: 1, limit: 999}, {
            ...(this.$store.state.campus.selected_class_group_id
                ? {
                  class_group_id: this.$store.state.campus.selected_class_group_id,
                }
                : {})
          }, [], this.report.id, this.selected_subject.report_subject_id)
              .then(() => {
                this.assessmentsDataHold = null

              })
        }).finally(() => {
          this.loading = false
          this.loaded = true
          this.$store.dispatch('loader/hide')
          this.$nextTick(() => {
            this.loadedEnrolmentsTable = true

          })
        })
      })


    },

    getTerms() {

      this.$store.dispatch('loader/show')
      this.termsDataHold = this.terms;
      Term.deleteAll()

      return Promise.all([Term.FetchAll(
          {
            page: 1,
            limit: 99,
          },
          {year: this.report.year, campus_id: this.$store.state.campus.selected_campus_id}
      )]).then(
          () => {
            this.termsDataHold = null;
          }
      ).catch(err => {
        console.log(err)
        this.handleError(err)
      }).finally(() => {
        this.$store.dispatch('loader/hide')
      });
    },
    getMarkGroups() {
      this.loadingMarkGroups = true
      this.markGroupsDatahold = this.mark_groups
      ReportMarkGroup.deleteAll()
      return ReportMarkGroup.FetchAll({
        page: 1,
        limit: 99
      }, {
        ...{
          subject_id: this.selected_subject.id
        }, ...(this.$store.state.campus.selected_class_group_id
            ? {
              class_group_id: this.$store.state.campus.selected_class_group_id,
            }
            : {})
      }, ['assessments'], this.report.id).then(() => {
        this.markGroupsDatahold = null
        this.markGroupsEditHold = JSON.parse(JSON.stringify(this.mark_groups))
      }).catch(err => {
        this.handleError(err)
      }).finally(() => {
        this.loadingMarkGroups = false
      })
    }, getFinalGroups() {
      this.loadingFinalGroups = true
      this.finalGroupsDatahold = this.final_groups
      ReportFinalGroup.deleteAll()
      return ReportFinalGroup.FetchAll({
        page: 1,
        limit: 99
      }, {
        ...{
          subject_id: this.selected_subject.id
        }, ...(this.$store.state.campus.selected_class_group_id
            ? {
              class_group_id: this.$store.state.campus.selected_class_group_id,
            }
            : {})
      }, [], this.report.id).then(() => {
        this.finalGroupsDatahold = null
        this.finalGroupsEditHold = JSON.parse(JSON.stringify(this.final_groups))
      }).catch(err => {
        console.log(err)
        this.handleError(err)
      }).finally(() => {
        this.loadingFinalGroups = false
      })
    },
    getReportFinalGroupOptions() {
      this.loadingGroupOptions = true
      this.groupOptionsDatahold = this.finalGroupOptions
      ReportFinalGroupOption.deleteAll()
      ReportFinalGroupOption.FetchAll({page: 1, limit: 999}, {
        ...(this.$store.state.campus.selected_class_group_id
            ? {
              class_group_id: this.$store.state.campus.selected_class_group_id,
            }
            : {})
      }, [], this.report.id, this.selected_subject.id).then(() => {
        this.groupOptionsDatahold = null

      }).catch(err => {
        console.log(err)
        this.handleError(err)
      }).finally(() => {
        this.loadingGroupOptions = false
      })
    },
    getGradeThresholds() {
      this.loadingGradeThresholds = true
      this.gradeThresholdsDatahold = this.grade_thresholds
      ReportSubjectGradeThreshold.deleteAll()
      return ReportSubjectGradeThreshold.FetchAll({
        page: 1,
        limit: 99
      }, [], this.report.id, {subject_id: this.selected_subject.id}).then(() => {
        this.gradeThresholdsDatahold = null
        this.gradeThresholdsEditHold = this.grade_thresholds
      }).catch(err => {
        this.handleError(err)
      }).finally(() => {
        this.loadingGradeThresholds = false
      })
    },

  }
  ,

  computed: {
    previousReportTotals() {
      return this.totals.length > 0 ? this.totals[this.totals.length - 1]?.finals[0]?.previous_report_totals ?? [] : []
    },
    finalGroupOptions() {
      if (this.groupOptionsDatahold === null) {
        return ReportFinalGroupOption.query().orderBy('type_id', 'desc').get()
      } else {
        return this.groupOptionsDatahold
      }
    },
    show_data() {
      return this.selected_subject.display_type_id !== 3 && this.selected_subject.display_type_id !== 4
    },
    comment_user_ids() {
      let user_ids = []
      ReportSubjectComment.query().get().map(comment => user_ids.push(comment.user_id))
      return user_ids
    },
    finalTotals() {
      if (this.report.is_final === 1 && this.totals.length > 0) {
        let totals = {}
        this.totals[this.totals.length - 1]?.finals.map(final => {
          totals[final.user_id] = final
        })
        return totals
      }
      return []
    },
    totals() {
      return ReportSubjectGradeTotals.query().get()
    }
    ,
    mark_groups_valid() {
      let total = 0
      this.markGroupsEditHold.map(group => total += group.weighting)

      return total === 100
    }, final_groups_valid() {
      let total = 0
      this.finalGroupsEditHold.map(group => total += group.weighting)

      return total === 100
    }
    ,
    final_groups() {
      if (this.finalGroupsDatahold === null) {
        return ReportFinalGroup.query().orderBy('id', 'desc').get()
      } else {
        return this.finalGroupsDatahold
      }
    }, mark_groups() {
      if (this.markGroupsDatahold === null) {
        return ReportMarkGroup.query().orderBy('id', 'desc').get()
      } else {
        return this.markGroupsDatahold
      }
    }, formal_mark_groups() {
      if (this.markGroupsDatahold === null) {
        return ReportMarkGroup.query().where('has_weight_assessments', 1).get()
      } else {
        return this.markGroupsDatahold
      }
    }
    ,
    grade_thresholds() {
      if (this.gradeThresholdsDatahold === null) {
        return ReportSubjectGradeThreshold.query().where('subject_id', this.selected_subject.id).orderBy('highest', 'desc').get()
      } else {
        return this.gradeThresholdsDatahold
      }
    }
    ,
    assessments() {
      if (this.assessmentsDataHold === null) {
        return Assessment.query().where((assessment) => {
          return (assessment.class_group_id === this.selected_class_group_id) || (assessment.is_global === 1)
        }).orderBy('assessment_date').get();
      } else {
        return this.assessmentsDataHold;
      }
    }
    ,
    orderedEnrolments() {
      if (this.loaded) {
        return this.$refs.nameTable.newData
      }
      return []
    },
    enrolments() {
      return Enrolment.query().with('user').with('status').get()
    }, users() {
      return User.query().with('enrolment_status').get()
    },
    assessmentGrades() {
      return ReportSubjectAssessmentGrades.query().get()
    },
    termOrderedAssessments() {
      if (this.loaded && this.assessments.length > 0) {
        let assessments = {}
        this.terms.map(term => {
          assessments[term.id] = ReportSubjectAssessmentGrades.query().where('term_id', term.id).first()
          assessments[term.id].assessments.map(assessment => {
            // assessment.grades = {}
            // assessment.absent_reason = {}
            let assessmentHold = Assessment.query().where('id', assessment.id).first()
            assessment.markgroup_id = assessmentHold.markgroup_id
            assessment.weighting = assessmentHold.weighting
            assessment.weighting_valid = Assessment.query().where('markgroup_id', assessment.markgroup_id).sum('weighting') === 100

            // User.query().get().map(user => {
            //   assessment.grades[user.id] = this.gradeEntityValue(assessment.id, user.id)
            //   assessment.absent_reason[user.id] = this.gradeEntityAbsent(assessment.id, user.id)
            // })
          })
        })
        return assessments
      }
      return {}
    },
    termOrderedMarkGroups() {
      let markgroups = {}
      this.terms.map(term => {
        markgroups[term.id] = {}
        markgroups[term.id].groups = this.markGroupTotals(term.id)
        markgroups[term.id].groups.map(group => {
          group.weighted_marks = {}
          User.query().get().map(user => {

            group.weighted_marks[user.id] = this.markGroupTotalValue(user.id, group.marks)
          })
        })
        markgroups[term.id].total = {}
        User.query().get().map(user => {
          markgroups[term.id].total[user.id] = this.markTermTotal(user.id, term.id)
        })

      })
      return markgroups
    },

    terms() {
      if (this.termsDataHold === null) {
        return Term.query().where(term => {
          // return this.report.is_final === 1 ? term.id === this.report.term_id : this.report.term_ids.includes(term.id)
          return this.report.term_ids.includes(term.id)
        }).orderBy('start').get();
      } else {
        return this.termsDataHold;
      }
    }
    ,

    ...
        mapState('campus', ["selected_campus_id", "all_campuses", "selected_class_group_id"]),


  }
  ,
  watch: {
    selected_class_group_id(newValue) {
      if (newValue !== null) {
        this.$store.dispatch("loader/show");
        this.loaded = false
        this.loadedEnrolmentsTable = false
        Term.deleteAll()
        Assessment.deleteAll()
        User.deleteAll()
        ReportSubjectGradeTotals.deleteAll()
        ReportSubjectComment.deleteAll()
        this.getTotals()
        this.getGradeThresholds()
        this.getComments()
        this.psInit()
        this.psInitMarkGroups()
        this.psInitThresholds()
        if (this.report.is_final === 1) {
          this.psInitFinalGroups()
          this.getReportFinalGroupOptions()
        }
        this.getTerms().then(() => {
          if (this.terms.length > 0) {
            this.selectTerms(this.report.term_ids)

          }

        })
      }
    }
  },
  mounted() {
    this.$store.dispatch("loader/show");
    EnrolmentStatus.FetchAll()
    this.$store.dispatch('campus/selectClassGroup', null).then(() => {
      this.getClassesGroups()
    })
    // Term.deleteAll()
    // Assessment.deleteAll()
    // User.deleteAll()
    // EnrolmentStatus.FetchAll()
    // ReportSubjectGradeTotals.deleteAll()
    // ReportSubjectComment.deleteAll()
    // this.getTotals()
    // this.getGradeThresholds()
    // this.getComments()
    // this.getTerms().then(() => {
    //   if (this.terms.length > 0) {
    //     this.selectTerms(this.report.term_ids)
    //
    //   }
    //
    // })


  }
  ,
}
;
</script>

<style lang="scss">


::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.bar-container {
  max-width: 99%;
  overflow-x: scroll;
  overflow-y: hidden;
}

.flippy {
  max-width: 100%;
}

.bar-container,
.flippy {
  transform: rotateX(180deg);
  -moz-transform: rotateX(180deg); /* Mozilla */
  -webkit-transform: rotateX(180deg); /* Safari and Chrome */
  -ms-transform: rotateX(180deg); /* IE 9+ */
  -o-transform: rotateX(180deg); /* Opera */
}
</style>
