<template>
  <div>
    <CampusHeader
        @start-creating="startCreate()"
        :show-mobile="false"
        :can-create="canCreate"
        :activeTab="activeTab"
        :manage-campuses="true"
        :class-group-subject-id="selectedSubject?selectedSubject.id:null"
        :manage-class-groups="false"
        :can-all-home-classes="true"
        :can-select-class-groups="isMarkgroupTabOpen"
        :can-select-home-classes="isLearnerTabOpen"
        :can-select="activeTab===0"
        :can-select-year="activeTab===0"
        :create-text="createText"
        @change-tab="changeTab"
        :hide-tabs="true"

        :header-text="headerText"
        :header_tabs="header_tabs"
        :can-all="true"
    ></CampusHeader>
    <section class="section pt-4 box">
      <b-tabs expanded multiline  :destroy-on-hide="true" @input="changeTab" :class="{'hidden-tabs':header_tabs.length<2}" v-model="activeTab">
        <b-tab-item v-if="useTab('reports')" label="Reports">
          <div class=" columns">
            <div class="column is-12">
              <ReportsIndex
                  @selected="option => handleReportSelect(option)"
                  @edit="option=>handleReportEdit(option)"
              >

              </ReportsIndex>
            </div>
          </div>
        </b-tab-item>

        <b-tab-item v-if="useTab('subjects')" label="Subjects">
          <div class=" columns">
            <div class="column is-12">
              <ReportSubjectsIndex
                  :force-edit="edit_report_subject_id"
                  :report="selectedReport"
                  @selected="option => handleReportSubjectSelect(option)"
                  @edit="option => handleReportSubjectEdit(option)"
              >
              </ReportSubjectsIndex>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item v-if="useTab('extramurals')" label="Extramurals">
          <div class=" columns">
            <div class="column is-12">
              <ReportExtramuralsIndex
                  :report="selectedReport"
                  :force-edit="edit_report_extramural_id"
                  @selected="option => handleReportExtramuralSelect(option)"
                  @edit="option => handleReportExtramuralEdit(option)"
              >
              </ReportExtramuralsIndex>
            </div>
          </div>
        </b-tab-item>

        <b-tab-item v-if="useTab('mark-groups')" label="Report Data">
          <div class=" columns">
            <div class="column is-12">
              <MarkGroups :report="selectedReport" :selected_subject="selectedSubject"></MarkGroups>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item v-if="useTab('extramural-comments')" label="Extramural Comments">
          <div class=" columns">
            <div class="column is-12">
              <ExtramuralComments :report="selectedReport"
                                  :selected_extramural="selectedExtramural"></ExtramuralComments>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item v-if="useTab('students')" label="Students">
          <div class=" columns">
            <div class="column is-12">
              <ReportStudentsIndex :report="selectedReport"></ReportStudentsIndex>
            </div>
          </div>
        </b-tab-item>

      </b-tabs>
    </section>
  </div>
</template>

<script>

import CampusHeader from "@/components/panelled-dash/CampusHeader";
import ReportsIndex from "@/components/reports/ReportsIndex";
import ReportForm from "@/components/reports/ReportForm";
import ReportSubjectsIndex from "@/components/reports/ReportSubjectsIndex";
import ReportExtramuralsIndex from "@/components/reports/ReportExtramuralsIndex";
import ReportSubjectForm from "@/components/reports/ReportSubjectForm";
import MarkGroups from "@/components/reports/MarkGroups";
import ReportStudentsIndex from "@/components/reports/ReportStudentsIndex";
import Report from "@/models/Report";
import ReportSubject from "@/models/ReportSubject";
import ReportExtramural from "@/models/ReportExtramural";
import ReportExtramuralForm from "@/components/reports/ReportExtramuralForm";
import ExtramuralComments from "@/components/reports/ExtramuralComments";
// import Enrolment from "@/models/Enrolment";

export default {
  name: "Reports",
  components: {
    ExtramuralComments,
    ReportsIndex,
    CampusHeader,
    ReportSubjectsIndex,
    MarkGroups,
    ReportStudentsIndex,
    ReportExtramuralsIndex,

  },
  data() {
    return {
      activeTabs: [],
      loaded: false,
      activeTab: 0,
      selectedReport: null,
      selectedSubject: null,
      selectedExtramural: null,
      selectedAssessment: null,
      search: null,
      loadingFilter: false,
      userDataHold: null,
      meta: Object,
      page: 1,
      limit: 20,
      loadingData: false,
      searchHold: null,
    };
  },


  methods: {
    changeTab(index) {
      this.$nextTick(() => {
        this.activeTab = index;

      })

      this.setQuery({tab: this.tabs[index].role,campus_id:this.$store.state.campus.selected_campus_id})
    },
    goToTabName(tabName) {
      this.activeTab = this.tabs.findIndex(tab => {
        return tab.role === tabName
      })

      this.setQuery({tab: tabName,campus_id:this.$store.state.campus.selected_campus_id})

    },
    startCreate() {
      if (this.activeTab === 0) {
        this.startCreateReport()
      }
      if (this.activeTab === 1) {
        this.startCreateReportSubject()
      }
      if (this.activeTab === 2) {
        this.startCreateReportExtramural()
      }
    },
    startCreateReportSubject() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          campus_id: this.$store.state.campus.selected_campus_id,
          edit: false,
          inModal: true,
          report: this.selectedReport
        },
        component: ReportSubjectForm,
        fullScreen: false,
        trapFocus: true,
        canCancel: ['outside', 'x']
      });
    }, startCreateReportExtramural() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          campus_id: this.$store.state.campus.selected_campus_id,
          edit: false,
          inModal: true,
          report: this.selectedReport
        },
        component: ReportExtramuralForm,
        fullScreen: false,
        trapFocus: true,
        canCancel: ['outside', 'x']
      });
    }, startCreateReport() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          campus_id: this.$store.state.campus.selected_campus_id,
          edit: false,
          manageTerms: true,
          inModal: true,
        },
        component: ReportForm,
        fullScreen: false,
        trapFocus: true,
        canCancel: ['outside', 'x'],
        events: {
          "created": (report_id) => {
            this.$store.dispatch('loader/show')
            Report.FetchById(report_id, ['terms']).finally(() => {
              this.$store.dispatch('loader/hide')
            })
          },
        },
      });
    },
    handleReportSubjectEdit(reportSubject) {
      this.setQuery({report_subject_id: reportSubject.id, edit: true})
      this.$buefy.modal.open({
        parent: this,
        props: {
          campus_id: this.$store.state.campus.selected_campus_id,
          edit: true,
          report: this.selectedReport,
          reportSubject: reportSubject,
          inModal: true,
        },
        component: ReportSubjectForm,
        fullScreen: false,
        trapFocus: true,
        canCancel: ['outside', 'x'],
        onCancel: () => {
          this.removeQuery(['report_subject_id', 'edit'])

        },
        events: {
          "close": () => {
            this.removeQuery(['report_subject_id', 'edit'])
          },
        }
      });
    }, handleReportExtramuralEdit(reportExtramural) {
      this.setQuery({report_extramural_id: reportExtramural.id, edit: true})
      this.$buefy.modal.open({
        parent: this,
        props: {
          campus_id: this.$store.state.campus.selected_campus_id,
          edit: true,
          report: this.selectedReport,
          reportExtramural: reportExtramural,
          inModal: true,
        },
        component: ReportExtramuralForm,
        fullScreen: false,
        trapFocus: true,
        onCancel: () => {
          this.removeQuery(['report_extramural_id', 'edit'])

        },
        events: {
          "close": () => {
            this.removeQuery(['report_extramural_id', 'edit'])
          },
        },
        canCancel: ['outside', 'x']
      });
    },
    handleReportEdit(report) {
      this.setQuery({report_id: report.id, tab: 'reports'})
      this.$buefy.modal.open({
        parent: this,
        props: {
          campus_id: this.$store.state.campus.selected_campus_id,
          edit: true,
          report: report,
          manageTerms: true,
          inModal: true,
        },
        component: ReportForm,
        fullScreen: false,
        trapFocus: true,
        onCancel: () => {
          this.removeQuery(['report_id', 'tab'])

        },
        events: {
          "close": () => {
            this.removeQuery(['report_id', 'tab'])
          },
        },
        canCancel: ['outside', 'x']
      });
    },
    activateTab(tabName) {
      if (!this.activeTabs.includes(tabName)) {
        this.activeTabs.push(tabName)
      }
    },
    deactivateTab(tabName) {
      this.activeTabs = this.activeTabs.filter(tab => tab !== tabName)
    },
    handleReportSelect(report, navigate = true) {
      this.$store.dispatch('loader/show')
      this.activateTab('subjects')
      this.activateTab('extramurals')
      this.activateTab('students')
      this.deactivateTab('extramural-comments')
      this.deactivateTab('mark-groups')
      return Report.FetchById(report.id, ['terms']).then(() => {
        this.selectedReport = Report.query().find(report.id)
        if (navigate) {
          this.$nextTick(() => {
            this.changeTab(1)
            this.setQuery({report_id: report.id,campus_id:this.$store.state.campus.selected_campus_id})
          })
        }
      }).catch(err => {
        this.handleError(err)
      }).finally(() => {
        this.$store.dispatch('loader/hide')
      })

    }, handleReportSubjectSelect(subject) {
      this.$store.dispatch('loader/show')
      this.setQuery({report_subject_id: subject.id})
      this.activateTab('mark-groups')

      ReportSubject.FetchById(this.selectedReport.id, subject.id).then((response) => {
        this.$nextTick(() => {
          this.selectedSubject = response.entities.reportSubjects[0]

          this.goToTabName('mark-groups')
        })
      }).catch(err => {
        this.handleError(err)
      }).finally(() => {
        this.$store.dispatch('loader/hide')
      })
    }, handleReportExtramuralSelect(extramural) {
      this.$store.dispatch('loader/show')
      this.setQuery({report_extramural_id: extramural.id})
      this.activateTab('extramural-comments')


      ReportExtramural.FetchById(this.selectedReport.id, extramural.id).then((response) => {
        this.selectedExtramural = response.entities.reportExtramurals[0]
        this.goToTabName('extramural-comments')

      }).catch(err => {
        this.handleError(err)
      }).finally(() => {
        this.$store.dispatch('loader/hide')
      })
    },
    handleAssessmentSelect(assessment) {

      this.selectedAssessment = assessment
      this.$nextTick(() => {
        this.changeTab(3)

      })
    },


    useTab(role) {
      return this.tabs.some((tab) => tab.role === role);
    },

  },

  computed: {
    edit_report_subject_id() {
      if (this.$route.query.report_subject_id && this.$route.query.tab === 'subjects' && this.$route.query.edit) {
        return parseInt(this.$route.query.report_subject_id)
      }
      return null
    }, edit_report_extramural_id() {
      if (this.$route.query.report_extramural_id && this.$route.query.tab === 'extramurals' && this.$route.query.edit) {
        return parseInt(this.$route.query.report_extramural_id)
      }
      return null
    },
    tabs() {
      let tabs = [{name: 'Reports', role: 'reports'}]
      if (this.activeTabs.includes('subjects')) {
        tabs.push({name: 'Subjects', role: 'subjects'})
      }
      if (this.activeTabs.includes('extramurals')) {
        tabs.push({name: 'Extramurals', role: 'extramurals'})
      }
      if (this.activeTabs.includes('mark-groups')) {
        tabs.push({name: 'Report Data', role: 'mark-groups'})
      }
      if (this.activeTabs.includes('extramural-comments')) {
        tabs.push({name: 'Extramural Comments', role: 'extramural-comments'})
      }
      if (this.activeTabs.includes('students')) {
        tabs.push({name: this.$tc('Learner', 2), role: 'students'})
      }

      return tabs
    },
    isMarkgroupTabOpen() {
      return this.activeTab === this.header_tabs.findIndex(tab => {
        return tab.title === 'Report Data'
      })
    },
    isLearnerTabOpen() {
      return this.activeTab === this.header_tabs.findIndex(tab => {
        return tab.title === this.$tc('Learner', 2)
      })
    },
    header_tabs() {
      let tabs = [{title: 'Reports'}]
      if (this.activeTabs.includes('subjects')) {
        tabs.push({title: 'Subjects'})
      }
      if (this.activeTabs.includes('extramurals')) {
        tabs.push({title: 'Extramurals'})
      }
      if (this.activeTabs.includes('mark-groups')) {
        tabs.push({title: 'Report Data'})
      }
      if (this.activeTabs.includes('extramural-comments')) {
        tabs.push({title: 'Extramural Comments'})
      }
      if (this.activeTabs.includes('students')) {
        tabs.push({title: this.$tc('Learner', 2)})
      }

      return tabs
    },
    createText() {
      if (this.activeTab === 0) {
        return 'New Report'
      }
      if (this.activeTab === 1) {
        return 'Add Subject'
      }
      if (this.activeTab === 2) {
        return 'Add EMAs/Other'
      }

      return ''
    },
    canCreate() {
      if (this.activeTab === 0 && this.$store.getters['entities/user-permissions/find']('create reports')) {
        return true
      }
      if (this.activeTab === 1 && this.$store.getters['entities/user-permissions/find']('create report-subjects') && (this.selectedReport.status_id < 7 || !!this.$store.getters['entities/user-permissions/find']('edit complete reports'))) {
        return true
      }
      if (this.activeTab === 2 && this.$store.getters['entities/user-permissions/find']('create report-extramurals') && (this.selectedReport.status_id < 7 || !!this.$store.getters['entities/user-permissions/find']('edit complete reports'))) {
        return true
      }
      // if (this.activeTab === 2 && this.$store.getters['entities/user-permissions/find']('create assessments')) {
      //   return true
      // }
      return false
    },
    headerText() {
      if (this.selectedReport === null) {
        return 'Reports'
      }
      return `${this.selectedReport.name}`
    }


  },
  mounted() {

    if (this.$route.query.report_id) {
      this.handleReportSelect({id: this.$route.query.report_id}, false).then(() => {
        if (this.$route.query.tab) {
          if (this.$route.query.tab === 'reports') {
            this.$nextTick(() => {
              this.handleReportEdit(this.selectedReport)
            })
          }
          if (this.$route.query.tab === 'subjects') {
            this.goToTabName('subjects')

          }
          if (this.$route.query.tab === 'extramurals') {
            this.goToTabName('extramurals')
          }
          if (this.$route.query.tab === 'students') {
            this.goToTabName('students')
          }
          if (this.$route.query.tab === 'mark-groups') {
            if (this.$route.query.report_subject_id) {
              this.handleReportSubjectSelect({id: this.$route.query.report_subject_id})
            }
          }
          if (this.$route.query.tab === 'extramural-comments') {
            if (this.$route.query.report_extramural_id) {
              this.handleReportExtramuralSelect({id: this.$route.query.report_extramural_id})
            }
          }
        }
      })
    }
    // if (this.useTab(this.$route.query.tab)) {
    //
    //   this.goToTabName(this.$route.query.tab)
    // } else {
    //   this.removeQuery(['tab'])
    // }

  },
};
</script>
