<template>
  <div>
    <!--    <div class="columns">-->
    <!--      <div class="column">-->
    <!--        <b-field grouped>-->
    <!--          <b-field expanded>-->
    <!--            <b-select expanded placeholder="Stage"-->
    <!--                      v-model="stageSearchId">-->
    <!--              <option-->
    <!--                  v-for="stage in stages"-->
    <!--                  :key="stage.id"-->
    <!--                  :value="stage.id"-->
    <!--              >{{ stage.id }} | {{ stage.name }}-->
    <!--              </option>-->
    <!--            </b-select>-->
    <!--          </b-field>-->
    <!--          <b-field>-->
    <!--            <b-button @click="clear()">-->
    <!--              Clear-->
    <!--            </b-button>-->
    <!--          </b-field>-->
    <!--        </b-field>-->
    <!--      </div>-->
    <!--    </div>-->
    <hr>
    <!--suppress JSUnusedLocalSymbols -->
    <b-table
        :bordered="false"
        :data="reportSubjects"
        :hoverable="true"
        :loading="loadingData"
        :row-class="(row, index) => 'has-cursor-move'"
        :striped="true"
        class="margin-top is-clickable"
        draggable
        @dragleave="dragLeave"
        @dragover="dragOver"
        @dragstart="payload=>dragStart(payload)"
        @drop="payload=>dragDrop(payload)"
    >
      <template #empty>
        <div class="has-text-centered">No Subjects</div>
      </template>
      <!--          :backend-sorting="true"-->
      <!--          :default-sort="['first_name', 'asc']"-->
      <!--          @sort="onSort"-->
      <b-table-column
          v-slot="props"
          field="id"
          label="ID"
          numeric
          sortable
          width="40"
      >{{ props.row.id }}
      </b-table-column>
      <!--      <b-table-column-->
      <!--          v-slot="props"-->
      <!--          label="ordinality"-->
      <!--          field="report_subject_ordinality"-->
      <!--          sortable-->
      <!--          width="40"-->
      <!--          numeric-->
      <!--      >{{ props.row.report_subject_ordinality }}-->
      <!--      </b-table-column>-->
      <b-table-column
          v-slot="props"
          field="code"
          label="Code"
          numeric
          sortable
          width="40"
      >{{ props.row.code }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          field="long_name"
          label="Name"
          sortable

      >{{ props.row.long_name }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          field="display_type_id"
          label="Display Type"
          sortable

      >{{ getDisplayType(props.row.display_type_id) }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          field="requires_educator_comment"
          label="Educator Comment Required"
          sortable

      >{{ props.row.requires_educator_comment ? 'Yes' : 'No' }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          field="requires_educator_checkpoints"
          label="Educator Checkpoint Required"
          sortable

      >{{ props.row.requires_educator_checkpoints ? 'Yes' : 'No' }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          field="educator_display_type_id"
          label="Educator Name"
          sortable

      >{{
          props.row.educator_display_type_id ? $store.state.reports.educator_display_type.find(type => type.id === props.row.educator_display_type_id).name : ''
        }}
      </b-table-column>
      <!--      <b-table-column-->
      <!--          v-slot="props"-->
      <!--          label="Submitted Comments"-->
      <!--          field="report_subject_comments_count"-->
      <!--          sortable-->

      <!--      >{{ props.row.requires_educator_comment?props.row.report_subject_comments_count:'N/A' }}-->
      <!--      </b-table-column>-->

      <!--          <b-table-column-->
      <!--              v-slot="props"-->
      <!--              sortable-->
      <!--              field="course_scope.learning_stream.name"-->
      <!--              label="Learning Stream"-->
      <!--          >{{ props.row.course_scope.learning_stream.name }}-->
      <!--          </b-table-column>-->
      <b-table-column
          v-slot="props"
          centered
          custom-key="actions"
          label=""
      >
        <div v-on:click.stop>
          <b-dropdown append-to-body aria-role="list" class="is-clickable" position="is-bottom-left">
            <template #trigger>
              <b-icon
                  :icon="$tc('icons.more')"/>
            </template>
            <b-dropdown-item aria-role="listitem"

                             @click="selectReportSubject(props.row)">Select
            </b-dropdown-item>
            <b-dropdown-item v-if="!!$store.getters['entities/user-permissions/find']('edit report-subjects') && (report.status_id<7 || !!$store.getters['entities/user-permissions/find']('edit complete reports'))"
                             aria-role="listitem"
                             @click="editReportSubject(props.row)">Edit
            </b-dropdown-item>
            <b-dropdown-item v-if="!!$store.getters['entities/user-permissions/find']('view report subject export')"
                             aria-role="listitem"
                             @click="startReportSubjectExport(props.row)">Export
            </b-dropdown-item>
            <b-dropdown-item v-if="!!$store.getters['entities/user-permissions/find']('delete report-subjects')&& (report.status_id<7 || !!$store.getters['entities/user-permissions/find']('edit complete reports'))"
                             aria-role="listitem"
                             @click="startDeleteReportSubject(props.row)">Delete
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </b-table-column>


    </b-table>
    <b-modal v-model="modal_open">
      <form class="modal-card" style="width: auto" @submit.prevent="downloadExport">
        <header class="modal-card-head">
          <p class="modal-card-title">Report Subject Export</p>
        </header>
        <section class="modal-card-body">
          <b-field label="Class Group">
            <b-select v-model="exportObject.class_group_id" expanded placeholder="Class Group" required>
              <option value="All">All</option>
              <option
                  v-for="classGroup of classGroups"
                  :key="classGroup.id"
                  :value="classGroup.id"
              >{{ classGroup.name }}
              </option>
            </b-select>
          </b-field>
          <b-field label="Export File Type">
            <b-select v-model="exportObject.file_type" expanded placeholder="Export type" required>
              <option v-for="type in file_types" :key="type.name" :value="type.value">
                {{ type.name }}
              </option>
            </b-select>
          </b-field>


        </section>
        <footer class="modal-card-foot">
          <button :loading="loadingExport" class="button" icon-right="download"
                  type="is-primary"
          >Download
          </button>
        </footer>
      </form>
      <b-loading v-model="loadingExport" :can-cancel="false" :is-full-page="false"></b-loading>
    </b-modal>

    <!--    <b-pagination-->
    <!--        class="mt-4"-->
    <!--        :total="meta.total"-->
    <!--        :current="page"-->
    <!--        :range-before="2"-->
    <!--        :range-after="2"-->
    <!--        :per-page="limit"-->
    <!--        aria-next-label="Next page"-->
    <!--        aria-previous-label="Previous page"-->
    <!--        aria-page-label="Page"-->
    <!--        aria-current-label="Current page"-->
    <!--        v-on:change="setPage"-->
    <!--    ></b-pagination>-->
  </div>

</template>

<script>
import Campus from "@/models/Campus";
import Phase from "@/models/Phase";
import Stage from "@/models/Stage";
import {mapState} from "vuex";
import ReportSubject from "@/models/ReportSubject";
import ClassGroup from "@/models/ClassGroup";
import axios from "axios";

export default {
  name: "ReportSubjectsIndex",
  data() {
    return {
      modal_open: false,
      search: null,
      loadingExport: false,
      loadingFilter: false,
      reportSubjectsDataHold: null,
      meta: Object,
      page: 1,
      use_modal: false,
      limit: 50,
      loadingData: false,
      searchHold: null,
      phaseSearchId: null,
      stageSearchId: null,
      draggingReportSubject: null,
      draggingReportSubjectStartIndex: null,
      exportObject: {
        subject_id: null,
        class_group_id: null,
        file_type: "csv"
      },
    };
  },
  props: {
    file_types: {
      type: Array,
      default() {
        return [{name: "csv", value: 'csv'}, {name: "xls", value: 'xls'}, {name: "xlsx", value: "xlsx"}]
      }
    },
    report: {
      type: Object,
      required: true
    }, forceEdit: {
      type: Number,
      default() {
        return null
      }
    },
    canDelete: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canCreate: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    startReportSubjectExport(reportSubject) {
      this.$store.dispatch('loader/show')
      ClassGroup.FetchAll({page: 1, limit: 999}, {subject_id: reportSubject.id}).then(() => {
        this.$store.dispatch('loader/hide')
        this.exportObject.subject_id = reportSubject.id
        this.modal_open = true

      })
    },
    downloadExport() {

      this.loadingExport = true;
      axios({
        url: `${process.env.VUE_APP_API_URL}/exports/report-subjects`, //your url
        method: "GET",
        params: {

          ...(this.exportObject.subject_id
              ? {
                subject_id: this.exportObject.subject_id,
              }
              : {}),

          ...(this.exportObject.class_group_id !== 'All'
              ? {
                class_group_id: this.exportObject.class_group_id,
              }
              : {}), ...(this.exportObject.file_type
              ? {
                export_file_type: this.exportObject.file_type,
              }
              : {}),
          ...(this.report.id
              ? {
                report_id: this.report.id,
              }
              : {}),


        },
        headers: {
          "Content-Type": "application/json",

          Authorization: "Bearer " + this.$store.state.auth.accessToken,
          "X-Requested-With": "XMLHttpRequest",
        },
      })
          .then((response) => {
            const link = document.createElement("a");
            link.href = response.data.exportUrl;
            document.body.appendChild(link);
            link.click();

            this.loadingExport = false;
            this.exportObject.subject_id = null
            this.exportObject.class_group_id = null
            this.modal_open = false
          })
          .catch((err) => {
            this.loadingExport = false;

            this.$store.dispatch("toast/createToast", {
              message: JSON.stringify(err.response.data),
            });
          });
    },

    processChange(payload, movePriority) {
      let updates = []
      ReportSubject.update({
        where: this.draggingReportSubject.report_subject_id,
        data: {order_index: payload.index, moved: movePriority}
      }).then(() => {
        this.reportSubjects.map((item, index) => {
          if (item.report_subject_ordinality !== index) {
            updates.push({id: item.report_subject_id, ordinality: index})
          }
        })
        this.loadingData = true
        ReportSubject.Order(this.report.id, updates).then(() => {
              this.reportSubjects.map((item) => {
                ReportSubject.update({
                  where: item.report_subject_id,
                  data: {moved: 0}
                })
              })

              this.loadingData = false
            }
        )

      })
    },
    dragStart(payload) {
      payload.event.dataTransfer.effectAllowed = 'move'
      this.draggingReportSubject = payload.row
      this.draggingReportSubjectStartIndex = payload.index
    },
    dragDrop(payload) {
      payload.event.target.closest('tr').classList.remove('hovered-over')
      if (payload.index < this.draggingReportSubject.order_index) {
        ReportSubject.update({
          where: payload.row.report_subject_id,
          data: {moved: 1}
        }).then(() => {
          this.processChange(payload, 0)
        })
      } else if (payload.index > this.draggingReportSubject.order_index) {
        this.processChange(payload, 1)

      }


    },
    dragOver(payload) {
      payload.event.dataTransfer.dropEffect = 'move'
      payload.event.target.closest('tr').classList.add('hovered-over')
      payload.event.preventDefault()
    },
    dragLeave(payload) {
      if (payload.event.target.nodeName !== "#text") {
        payload.event.target.closest('tr').classList.remove('hovered-over')
      }
      payload.event.preventDefault()
    },
    startDeleteReportSubject(reportSubject) {
      this.$buefy.dialog.confirm({
        title: "Deleting report subject",
        message:
            "Are you sure you want to <b>delete</b> this report subject? This action cannot be undone.",
        confirmText: "Delete Report Subject",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteReportSubject(reportSubject),
      });
    },
    deleteReportSubject(reportSubject) {
      this.$store.dispatch("loader/show");
      ReportSubject.Delete({report_id: this.report.id, id: reportSubject.id}).then(
          () => {
            this.$buefy.snackbar.open('Report Subject deleted!')
            this.$store.dispatch("loader/hide");
          }
      ).catch(
          err => {
            this.handleError(err)
          }
      )

    },
    getDisplayType(id) {
      return this.$store.state.reports.subject_display_type.find(type => type.id === id).name
    },
    clear() {
      this.phaseSearchId = null
      this.stageSearchId = null
    },
    onSort(field, order) {

      this.order_by = field;
      this.order_direction = order;
    },

    selectReportSubject(reportSubject) {
      this.$emit('selected', reportSubject)
    },
    editReportSubject(reportSubject) {
      this.$emit('edit', reportSubject)
    },

    setPage(pageNumber, initial = false) {
      this.loadingData = true;
      this.reportSubjectsDataHold = this.reportSubjects;
      ReportSubject.deleteAll();
      this.page = pageNumber;

      return ReportSubject.FetchAll(
          {
            page: this.page,
            limit: this.limit,
          },
          this.filters, [], this.report.id, ['report_subject_comments_count', 'report_subject_users_count']
      ).then(
          () => {

            this.loadingData = false;
            this.reportSubjectsDataHold = null;
            this.reportSubjects.map((item, index) => {
              ReportSubject.update({
                where: item.report_subject_id,
                data: {order_index: index, moved: 0}
              })

            })

            if (!initial) {
              this.$cookies.set(
                  `report_subject_filters`,
                  {
                    page: this.page,
                    phaseSearchId: this.phaseSearchId,
                    stageSearchId: this.stageSearchId
                  },
                  "1d"
              );
            }
          }
      );
    },
  },
  watch: {

    all_campuses(value) {
      if (value === true) {
        this.phaseSearchId = null
        this.stageSearchId = null
      }
    },
    selected_campus_id() {
      this.phaseSearchId = null
      this.stageSearchId = null
    },
    filters() {
      this.setPage(this.page);
    },
  },
  computed: {
    classGroups() {
      return ClassGroup.query().where('subject_id', this.exportObject.subject_id).get()
    },
    phase_filter: {
      set(val) {
        this.phaseSearchId = val
        this.stageSearchId = null
      }, get() {
        return this.phaseSearchId
      }
    },
    ...mapState('campus', ["selected_campus_id", "all_campuses"]),
    campuses() {
      return Campus.query().get();
    },
    phases() {
      return Phase.query().where((phase) => {
        return this.$store.state.campus.all_campuses ? true : phase.campus_id === this.$store.state.campus.selected_campus_id
      }).get()
    },
    stages() {
      return Stage.query().where((phase) => {
        return this.$store.state.campus.all_campuses ? true : phase.campus_id === this.$store.state.campus.selected_campus_id
      }).get()
    },
    filters() {
      return {
        ...(this.order_direction
            ? {
              order_direction: this.order_direction,
            }
            : {}), ...(this.order_by
            ? {
              order_by: this.order_by,
            }
            : {}),
        ...(this.phaseSearchId
            ? {
              phase_id: this.phaseSearchId,
            }
            : {}), ...(this.stageSearchId
            ? {
              stage_id: this.stageSearchId,
            }
            : {}),
        ...(this.$store.state.campus.selected_campus_id && !this.$store.state.campus.all_campuses
            ? {campus_id: this.$store.state.campus.selected_campus_id}
            : {}), year: this.$store.state.dates.year
      };
    },
    reportSubjects() {
      if (this.reportSubjectsDataHold === null) {
        return ReportSubject.query().with('subject').orderBy('order_index').orderBy('moved').get();
      } else {
        return this.reportSubjectsDataHold;
      }
    },

  },
  mounted() {
    this.$store.state.documentation.tab_name = 'subjects'
    this.$store.state.documentation.active_doc_document_path = './Reports/Report Subjects.md'
    ReportSubject.deleteAll()
    this.$store.dispatch("loader/show");
    if (this.$cookies.isKey(`report_subject_filters`)) {
      let filters = this.$cookies.get(`report_subject_filters`);
      this.page = filters.page;
      this.phaseSearchId = filters.phaseSearchId;
      this.stageSearchId = filters.stageSearchId
      this.order_by = filters.order_by
      this.order_direction = filters.order_direction
      this.$cookies.remove(`report_subject_filters`);
    }

    Promise.all([Stage.FetchAll({
      page: 1,
      limit: 999
    }, {
      ...(this.$store.state.campus.selected_campus_id && !this.$store.state.campus.all_campuses
          ? {campus_id: this.$store.state.campus.selected_campus_id}
          : {}), year: this.$store.state.dates.year
    }, ['stages'])]).then(() => {
      this.$store.dispatch("loader/hide");
      this.setPage(this.page, true).then(() => {
        if (this.forceEdit !== null) {
          this.$nextTick(() => {
            this.editReportSubject(ReportSubject.query().with('subject').where('id', this.forceEdit).first())
          })

        }
      });
    }).catch(
        err => {
          this.$store.dispatch("loader/hide");

          if (err.response.status === 422) {
            this.handleError(err)
          } else {
            this.handleError(err)
          }
        }
    );
  },
};
</script>
