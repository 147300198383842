<template>
  <form @submit.prevent="submit">
    <div :class="{'modal-card':inModal}" style="width: auto;;min-height: 300px">
      <header v-if="inModal" class="modal-card-head">
        <p class="modal-card-title">{{ edit === false ? 'Create a new' : 'Edit' }} Report Extramural</p>
      </header>
      <section :class="{'modal-card-body':inModal}">
        <b-field v-if="!edit" expanded label="Extramural">
          <ExtramuralsFilter
              @selected="option => selectExtramural(option)"
              :type="'autocomplete'"
              :campus_id="campus_id"
          ></ExtramuralsFilter>
        </b-field>
        <b-field v-if="loaded" style="overflow: hidden" label="Description">
          <ckeditor style="overflow: hidden"
                    :editor="editor"
                    v-model="reportExtramuralObject.description"
                    :config="editorConfig"
          ></ckeditor>
        </b-field>
        <b-field label="Educator Comment Required">
          <b-switch :true-value="1" :false-value="0" v-model="reportExtramuralObject.requires_educator_comment">
            {{ reportExtramuralObject.requires_educator_comment ? 'Yes' : 'No' }}
          </b-switch>
        </b-field>

      </section>
      <footer v-if="inModal" :class="{'modal-card-foot':inModal}">
        <b-button
            :disabled="edit?!$store.getters['entities/user-permissions/find']('edit report-extramurals'):!$store.getters['entities/user-permissions/find']('create report-extramurals')"
            native-type="submit" type="is-primary" :icon-right="$tc(`icons.${edit===false?'create':'edit'}`)">Save
        </b-button>
      </footer>
      <div class="pt-3" v-else>
        <b-field position="is-centered" grouped>
          <b-field position="is-centered">
            <b-button
                :disabled="edit?!$store.getters['entities/user-permissions/find']('edit report-extramurals'):!$store.getters['entities/user-permissions/find']('create report-extramurals')"
                native-type="submit" :icon-right="$tc(`icons.${edit===false?'create':'edit'}`)" type="is-primary">Save
            </b-button>
          </b-field>
        </b-field>
      </div>
    </div>
    <b-loading v-model="loading" :is-full-page="false" :can-cancel="false"></b-loading>
  </form>
</template>

<script>

import Term from "@/models/Term";
import ExtramuralsFilter from "@/components/panelled-dash/ExtramuralsFilter";
import ReportExtramural from "@/models/ReportExtramural";
import Extramural from "@/models/Extramural";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import {editorConfig} from "@/editorConfig";

export default {
  name: "ReportExtramuralForm",
  components: {ExtramuralsFilter},
  data() {
    return {
      loaded: false,
      loading: false,
      editor: ClassicEditor,
      editorConfig: {
        ...editorConfig,
        ...{placeholder: "The description to appear on reports."},
      },
      reportExtramuralObject: {
        id: null,
        requires_educator_comment: 0,
        description: null
        // teacher_id: null,
        // educator_name: null,
        // checkpoints: [],
      }
    }
  },

  computed: {
    teachers() {
      return Extramural.query().whereId(this.reportExtramuralObject.id).with('teachers').first().teachers
    },
    termsData() {
      if (this.manageTerms) {
        return Term.query().where('parent_id', null).where(term => {
          return new Date(term.start).getFullYear() === this.reportExtramuralObject.year
        }).with('children').orderBy('start').get()
      }
      return this.terms
    },

  },
  methods: {


    selectExtramural(option) {
      this.reportExtramuralObject.id = option.id
    },
    submit() {
      if (this.edit) {
        ReportExtramural.Update({
              report_id: this.report.id,
              id: this.reportExtramuralObject.id,
              description: this.reportExtramuralObject.description,
              requires_educator_comment: this.reportExtramuralObject.requires_educator_comment === null ? 0 : this.reportExtramuralObject.requires_educator_comment,


            }
        ).then(() => {
          this.$buefy.snackbar.open(`Report Extramural updated!`)
          this.$emit('close')
        }).catch(err => {
          this.handleError(err)
        })
      } else {
        ReportExtramural.Store({

          report_id: this.report.id,
          id: this.reportExtramuralObject.id,
          description: this.reportExtramuralObject.description,
          requires_educator_comment: this.reportExtramuralObject.requires_educator_comment,


        }).then(() => {
          this.$buefy.snackbar.open(`Report Extramural created!`)
          this.$emit('close')
        }).catch(err => {
          this.handleError(err)
        })
      }
    }
  }
  ,
  mounted() {
    if (this.edit) {
      this.reportExtramuralObject = JSON.parse(JSON.stringify(this.reportExtramural))
      if (this.reportExtramuralObject.report_description_override !== null) {
        this.reportExtramuralObject.description = this.reportExtramuralObject.report_description_override
      }
      this.loaded = true

      // this.loading = true
      // Extramural.FetchById(this.reportExtramuralObject.id, ['teachers']).then((
      //     this.loading = false
      // ))
    }
    this.loaded = true
  }
  ,
  props: {

    campus_id: {
      type: Number,
      default
          () {
        return 0
      }
    }, term_id: {
      type: Number,
      default
          () {
        return null
      }
    }, parent_id: {
      type: Number,
      default
          () {
        return null
      }
    },
    report:
        {
          type: Object,
          required: true
        }
    ,
    reportExtramural:
        {
          type: Object,
          default
              () {
            return {
              name: ''
            }
          }
          ,
        }
    ,
    inModal: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    ,
    edit: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    ,
  }
}
</script>

