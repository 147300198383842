<template>
  <form @submit.prevent="submit">
    <div :class="{'modal-card':inModal}" style="width: auto;">
      <header v-if="inModal" class="modal-card-head">
        <p class="modal-card-title">{{ edit === false ? 'Create a new' : 'Edit' }} Report User Comment</p>
      </header>
      <section :class="{'modal-card-body':inModal}">

        <b-field expanded label="Mentor/Tutor">
          <b-input v-model="reportUserCommentObject.commenter_name" required expanded placeholder="Mentor/Tutor"
          >
          </b-input>
        </b-field>

        <b-field :type="{'is-warning':isCloseToLimit&&!isLimitExceeded,'is-danger':isLimitExceeded}" :message="{'Character limit exceeded':isLimitExceeded}" v-if="loaded" expanded label="General Comment"
                 style="overflow: hidden">
          <ckeditor :editor="editor" v-model="reportUserCommentObject.comment" :config="editorConfig"></ckeditor>
        </b-field>
        <p  class="has-text-right">
        <svg :class="{'limit-close':isCloseToLimit&&!isLimitExceeded,'limit-exceeded':isLimitExceeded}" class="progressCircleChart" viewbox="0 0 60 60" width="60" height="60" xmlns="http://www.w3.org/2000/svg">
            <circle stroke="hsl(0, 0%, 93%)" stroke-width="3" fill="none" cx="30" cy="30" r="25"/>
            <circle ref="progressCircle" class="progressCircleChartCircle" stroke="hsl(202, 92%, 59%)" stroke-width="3"
                    stroke-dasharray="134,534" stroke-linecap="round" fill="none" cx="30" cy="30" r="25"/>
            <text  class="progressCircleChartCharacters" x="50%" y="50%" dominant-baseline="central"
                  text-anchor="middle">{{currentCharacters}}</text>
        </svg></p>

        <b-field v-if="hasSummaryComment" expanded label="Summary Commenter Name">
          <b-input v-model="reportUserCommentObject.summary_commenter_name" expanded placeholder="Name"
          >
          </b-input>
        </b-field>
        <b-field v-if="hasSummaryComment" expanded label="Summary Comment">
          <b-input v-model="reportUserCommentObject.summary_comment" expanded placeholder="Summary Comment"
                   type="textarea"
          >
          </b-input>
        </b-field>


      </section>
      <footer v-if="inModal" :class="{'modal-card-foot':inModal}">
        <b-button
            :disabled="edit?(!$store.getters['entities/user-permissions/find']('edit report-user-comments')|| reportStatus==='closed'||this.isLimitExceeded):(!$store.getters['entities/user-permissions/find']('create report-user-comments')|| reportStatus==='closed'||this.isLimitExceeded)"
            native-type="submit" type="is-primary" :icon-right="$tc(`icons.${edit===false?'create':'edit'}`)">Save
        </b-button>
      </footer>
      <div class="pt-3" v-else>
        <b-field position="is-centered" grouped>
          <b-field position="is-centered">
            <b-button
                :disabled="edit?(!$store.getters['entities/user-permissions/find']('edit report-user-comments')|| reportStatus==='closed'||this.isLimitExceeded):(!$store.getters['entities/user-permissions/find']('create report-user-comments')|| reportStatus==='closed'||this.isLimitExceeded)"
                native-type="submit" :icon-right="$tc(`icons.${edit===false?'create':'edit'}`)" type="is-primary">Save
            </b-button>
          </b-field>
        </b-field>
      </div>
    </div>
    <b-loading v-model="loading" :is-full-page="false" :can-cancel="false"></b-loading>
  </form>
</template>

<script>

import ReportUserComment from "@/models/ReportUserComment";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import {editorConfig} from "@/editorConfig";

export default {
  name: "ReportUserCommentForm",
  data() {
    return {
      loading: false,
      loaded: false,
      editor: ClassicEditor,
      maxCharacters: 1600,
      currentCharacters: 0,
      characterProgress:0,
      isLimitExceeded:false,
      isCloseToLimit:false,
      circleDashArray:0,
      editorConfig: {

        ...editorConfig,
        ...{
          placeholder: "General Comment", wordCount: {
            onUpdate: stats => {
              const circleCircumference=Math.floor( 2 * Math.PI * 25)
              this.currentCharacters = stats.characters
              this.charactersProgress = stats.characters / this.maxCharacters * circleCircumference
              this.isLimitExceeded = stats.characters > this.maxCharacters;
              this.isCloseToLimit = !this.isLimitExceeded && stats.characters > this.maxCharacters * .8;
              this.circleDashArray = Math.min( this.charactersProgress, circleCircumference );

              this.$refs.progressCircle.setAttribute( 'stroke-dasharray', `${ this.circleDashArray },${ circleCircumference }` );
            }
          }
        },
      },
      reportUserCommentObject: {
        report_id: this.reportId,
        comment: null,
        user_id: this.userId,
        commenter_name: null,
        summary_comment: null,
        summary_commenter_name: null
      }
    }
  },

  methods: {
    submit() {
      if (this.edit) {
        ReportUserComment.Update({
              report_id: this.reportId,
              user_id: this.reportUserCommentObject.user_id,
              id: this.reportUserCommentObject.id,
              comment: this.reportUserCommentObject.comment,
              commenter_name: this.reportUserCommentObject.commenter_name,
              summary_comment: this.reportUserCommentObject.summary_comment,
              summary_commenter_name: this.reportUserCommentObject.summary_commenter_name
            }
        ).then(() => {
          this.$buefy.snackbar.open(`Report User Comment updated!`)
          this.$emit('close')
        }).catch(err => {
          this.handleError(err)
        })
      } else {
        ReportUserComment.Store({
          report_id: this.reportId,
          user_id: this.reportUserCommentObject.user_id,
          comment: this.reportUserCommentObject.comment,
          commenter_name: this.reportUserCommentObject.commenter_name,
          summary_comment: this.reportUserCommentObject.summary_comment,
          summary_commenter_name: this.reportUserCommentObject.summary_commenter_name


        }).then(() => {
          this.$buefy.snackbar.open(`Report User Comment created!`)
          this.$emit('close')
        }).catch(err => {
          this.handleError(err)
        })
      }
    }
  }
  ,
  mounted() {
    if (this.edit) {
      this.reportUserCommentObject = this.reportUserComment
      this.loaded = true
    }
    this.loaded = true
  }
  ,
  props: {
    reportId: {
      type: Number,
      required: true
    }, userId: {
      type: Number,
      required: true
    },

    reportStatus: {
      type: String,
      default
          () {
        return 'open'
      }
    },
    reportUserComment:
        {
          type: Object,
          required: false

        }
    ,
    inModal: {
      type: Boolean,
      default
          () {
        return false
      }
    }, hasSummaryComment: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    ,
    edit: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    ,
  }
}
</script>


