<template>
  <div>
    <!--    <div class="columns">-->
    <!--      <div class="column">-->
    <!--        <b-field grouped>-->
    <!--          <b-field expanded>-->
    <!--            <b-select expanded placeholder="Stage"-->
    <!--                      v-model="stageSearchId">-->
    <!--              <option-->
    <!--                  v-for="stage in stages"-->
    <!--                  :key="stage.id"-->
    <!--                  :value="stage.id"-->
    <!--              >{{ stage.id }} | {{ stage.name }}-->
    <!--              </option>-->
    <!--            </b-select>-->
    <!--          </b-field>-->
    <!--          <b-field>-->
    <!--            <b-button @click="clear()">-->
    <!--              Clear-->
    <!--            </b-button>-->
    <!--          </b-field>-->
    <!--        </b-field>-->
    <!--      </div>-->
    <!--    </div>-->
    <hr>
    <!--suppress JSUnusedLocalSymbols -->
    <b-table
        :row-class="(row, index) => 'has-cursor-move'"
        class="margin-top is-clickable"
        :data="reportExtramurals"
        :loading="loadingData"
        :striped="true"
        draggable
        @dragover="dragOver"
        @dragleave="dragLeave"
        @dragstart="payload=>dragStart(payload)"
        @drop="payload=>dragDrop(payload)"
        :hoverable="true"
        :bordered="false"
    >
      <template #empty>
        <div class="has-text-centered">No Extramurals</div>
      </template>
      <!--          :backend-sorting="true"-->
      <!--          :default-sort="['first_name', 'asc']"-->
      <!--          @sort="onSort"-->
      <b-table-column
          v-slot="props"
          label="ID"
          field="id"
          sortable
          width="40"
          numeric
      >{{ props.row.id }}
      </b-table-column>
      <!--      <b-table-column-->
      <!--          v-slot="props"-->
      <!--          label="ordinality"-->
      <!--          field="report_extramural_ordinality"-->
      <!--          sortable-->
      <!--          width="40"-->
      <!--          numeric-->
      <!--      >{{ props.row.report_extramural_ordinality }}-->
      <!--      </b-table-column>-->
      <b-table-column
          v-slot="props"
          label="Name"
          field="name"
          sortable

      >{{ props.row.name }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="Educator Comment Required"
          field="requires_educator_comment"
          sortable

      >{{ props.row.requires_educator_comment === 1 ? 'Yes' : 'No' }}
      </b-table-column>

      <!--      <b-table-column-->
      <!--          v-slot="props"-->
      <!--          label="Submitted Comments"-->
      <!--          field="report_extramural_comments_count"-->
      <!--          sortable-->

      <!--      >{{ props.row.requires_educator_comment?props.row.report_extramural_comments_count:'N/A' }}-->
      <!--      </b-table-column>-->

      <!--          <b-table-column-->
      <!--              v-slot="props"-->
      <!--              sortable-->
      <!--              field="course_scope.learning_stream.name"-->
      <!--              label="Learning Stream"-->
      <!--          >{{ props.row.course_scope.learning_stream.name }}-->
      <!--          </b-table-column>-->
      <b-table-column
          v-slot="props"
          label=""
          custom-key="actions"
          centered
      >
        <div v-on:click.stop>
          <b-dropdown position="is-bottom-left" append-to-body aria-role="list" class="is-clickable">
            <template #trigger>
              <b-icon
                  :icon="$tc('icons.more')"/>
            </template>
            <b-dropdown-item @click="selectReportExtramural(props.row)"

                            aria-role="listitem">Select
            </b-dropdown-item>
            <b-dropdown-item @click="editReportExtramural(props.row)"
                            v-if="!!$store.getters['entities/user-permissions/find']('edit report-extramurals') && (report.status_id<7 || !!$store.getters['entities/user-permissions/find']('edit complete reports'))"
                            aria-role="listitem">Edit
            </b-dropdown-item>
            <b-dropdown-item @click="startDeleteReportExtramural(props.row)"
                            v-if="!!$store.getters['entities/user-permissions/find']('delete report-extramurals')&& (report.status_id<7 || !!$store.getters['entities/user-permissions/find']('edit complete reports'))"
                            aria-role="listitem">Delete
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </b-table-column>


    </b-table>
    <!--    <b-pagination-->
    <!--        class="mt-4"-->
    <!--        :total="meta.total"-->
    <!--        :current="page"-->
    <!--        :range-before="2"-->
    <!--        :range-after="2"-->
    <!--        :per-page="limit"-->
    <!--        aria-next-label="Next page"-->
    <!--        aria-previous-label="Previous page"-->
    <!--        aria-page-label="Page"-->
    <!--        aria-current-label="Current page"-->
    <!--        v-on:change="setPage"-->
    <!--    ></b-pagination>-->
  </div>

</template>

<script>
import Campus from "@/models/Campus";
import Phase from "@/models/Phase";
import Stage from "@/models/Stage";
import {mapState} from "vuex";
import ReportExtramural from "@/models/ReportExtramural";

export default {
  name: "ReportExtramuralsIndex",
  data() {
    return {

      search: null,
      loadingFilter: false,
      reportExtramuralsDataHold: null,
      meta: Object,
      page: 1,
      use_modal: false,
      limit: 50,
      loadingData: false,
      searchHold: null,
      phaseSearchId: null,
      stageSearchId: null,
      draggingReportExtramural: null,
      draggingReportExtramuralStartIndex: null,
    };
  },
  props: {
    report: {
      type: Object,
      required: true
    }, forceEdit: {
      type: Number,
      default() {
        return null
      }
    },
    canDelete: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canCreate: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    processChange(payload, movePriority) {
      let updates = []
      ReportExtramural.update({
        where: this.draggingReportExtramural.report_extramural_id,
        data: {order_index: payload.index, moved: movePriority}
      }).then(() => {
        this.reportExtramurals.map((item, index) => {
          if (item.report_extramural_ordinality !== index) {
            updates.push({id: item.report_extramural_id, ordinality: index})
          }
        })
        this.loadingData = true
        ReportExtramural.Order(this.report.id, updates).then(() => {
              this.reportExtramurals.map((item) => {
                ReportExtramural.update({
                  where: item.report_extramural_id,
                  data: {moved: 0}
                })
              })

              this.loadingData = false
            }
        )

      })
    },
    dragStart(payload) {
      payload.event.dataTransfer.effectAllowed = 'move'
      this.draggingReportExtramural = payload.row
      this.draggingReportExtramuralStartIndex = payload.index
    },
    dragDrop(payload) {
      payload.event.target.closest('tr').classList.remove('hovered-over')
      if (payload.index < this.draggingReportExtramural.order_index) {
        ReportExtramural.update({
          where: payload.row.report_extramural_id,
          data: {moved: 1}
        }).then(() => {
          this.processChange(payload, 0)
        })
      } else if (payload.index > this.draggingReportExtramural.order_index) {
        this.processChange(payload, 1)

      }


    },
    dragOver(payload) {
      payload.event.dataTransfer.dropEffect = 'move'
      payload.event.target.closest('tr').classList.add('hovered-over')
      payload.event.preventDefault()
    },
    dragLeave(payload) {
      if (payload.event.target.nodeName !== "#text") {
        payload.event.target.closest('tr').classList.remove('hovered-over')
      }
      payload.event.preventDefault()
    },
    startDeleteReportExtramural(reportExtramural) {
      this.$buefy.dialog.confirm({
        title: "Deleting report extramural",
        message:
            "Are you sure you want to <b>delete</b> this report extramural? This action cannot be undone.",
        confirmText: "Delete Report Extramural",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteReportExtramural(reportExtramural),
      });
    },
    deleteReportExtramural(reportExtramural) {
      this.$store.dispatch("loader/show");
      ReportExtramural.Delete({report_id: this.report.id, id: reportExtramural.id}).then(
          () => {
            this.$buefy.snackbar.open('Report Extramural deleted!')
            this.$store.dispatch("loader/hide");
          }
      ).catch(
          err => {
            this.handleError(err)
          }
      )

    },

    clear() {
      this.phaseSearchId = null
      this.stageSearchId = null
    },
    onSort(field, order) {

      this.order_by = field;
      this.order_direction = order;
    },

    selectReportExtramural(reportExtramural) {
      this.$emit('selected', reportExtramural)
    },
    editReportExtramural(reportExtramural) {
      this.$emit('edit', reportExtramural)
    },

    setPage(pageNumber, initial = false) {
      this.loadingData = true;
      this.reportExtramuralsDataHold = this.reportExtramurals;
      ReportExtramural.deleteAll();
      this.page = pageNumber;

      return ReportExtramural.FetchAll(
          {
            page: this.page,
            limit: this.limit,
          },
          this.filters, [], this.report.id, ['report_extramural_comments_count', 'report_extramural_users_count']
      ).then(
          () => {
            this.loadingData = false;
            this.reportExtramuralsDataHold = null;
            this.reportExtramurals.map((item, index) => {
              ReportExtramural.update({
                where: item.report_extramural_id,
                data: {order_index: index, moved: 0}
              })

            })

            if (!initial) {
              this.$cookies.set(
                  `report_extramural_filters`,
                  {
                    page: this.page,
                    phaseSearchId: this.phaseSearchId,
                    stageSearchId: this.stageSearchId
                  },
                  "1d"
              );
            }
          }
      );
    },
  },
  watch: {

    all_campuses(value) {
      if (value === true) {
        this.phaseSearchId = null
        this.stageSearchId = null
      }
    },
    selected_campus_id() {
      this.phaseSearchId = null
      this.stageSearchId = null
    },
    filters() {
      this.setPage(this.page);
    },
  },
  computed: {
    phase_filter: {
      set(val) {
        this.phaseSearchId = val
        this.stageSearchId = null
      }, get() {
        return this.phaseSearchId
      }
    },
    ...mapState('campus', ["selected_campus_id", "all_campuses"]),
    campuses() {
      return Campus.query().get();
    },
    phases() {
      return Phase.query().where((phase) => {
        return this.$store.state.campus.all_campuses ? true : phase.campus_id === this.$store.state.campus.selected_campus_id
      }).get()
    },
    stages() {
      return Stage.query().where((phase) => {
        return this.$store.state.campus.all_campuses ? true : phase.campus_id === this.$store.state.campus.selected_campus_id
      }).get()
    },
    filters() {
      return {
        ...(this.order_direction
            ? {
              order_direction: this.order_direction,
            }
            : {}), ...(this.order_by
            ? {
              order_by: this.order_by,
            }
            : {}),
        ...(this.phaseSearchId
            ? {
              phase_id: this.phaseSearchId,
            }
            : {}), ...(this.stageSearchId
            ? {
              stage_id: this.stageSearchId,
            }
            : {}),
        ...(this.$store.state.campus.selected_campus_id && !this.$store.state.campus.all_campuses
            ? {campus_id: this.$store.state.campus.selected_campus_id}
            : {}), year: this.$store.state.dates.year
      };
    },
    reportExtramurals() {
      if (this.reportExtramuralsDataHold === null) {
        return ReportExtramural.query().with('extramural').orderBy('order_index').orderBy('moved').get();
      } else {
        return this.reportExtramuralsDataHold;
      }
    },

  },
  mounted() {
    this.$store.state.documentation.tab_name = 'extramurals'
    this.$store.state.documentation.active_doc_document_path = './Reports/Report EMAs or Other.md'
    ReportExtramural.deleteAll()
    this.$store.dispatch("loader/show");
    if (this.$cookies.isKey(`report_extramural_filters`)) {
      let filters = this.$cookies.get(`report_extramural_filters`);
      this.page = filters.page;
      this.phaseSearchId = filters.phaseSearchId;
      this.stageSearchId = filters.stageSearchId
      this.order_by = filters.order_by
      this.order_direction = filters.order_direction
      this.$cookies.remove(`report_extramural_filters`);
    }

    Promise.all([Stage.FetchAll({
      page: 1,
      limit: 999
    }, {
      ...(this.$store.state.campus.selected_campus_id && !this.$store.state.campus.all_campuses
          ? {campus_id: this.$store.state.campus.selected_campus_id}
          : {}), year: this.$store.state.dates.year
    }, ['stages'])]).then(() => {
      this.$store.dispatch("loader/hide");
      this.setPage(this.page, true).then(() => {
        if (this.forceEdit !== null) {
          this.$nextTick(() => {
            this.editReportExtramural(ReportExtramural.query().with('extramural').where('id', this.forceEdit).first())
          })

        }
      });
    }).catch(
        err => {
          this.$store.dispatch("loader/hide");

          if (err.response.status === 422) {
            this.handleError(err)
          } else {
            this.handleError(err)
          }
        }
    );
  },
};
</script>
