<template>
  <div v-if="loadedTable" class="columns is-multiline">
    <div class="column is-12">
      <div class="">
        <div class="">
          <div class="columns">
            <div class="column">
              <p class="has-text-centered  is-size-3">{{ $tc('Learner', 2) }}:</p>
              <b-table

                  class="specific-header-height"
                  :data="users"
                  :striped="true"
                  ref="nameTable"
                  :loading="loading"
                  v-if="loaded"
                  :hoverable="true"
                  :bordered="true"
                  @sort="onSort"
              >
                <template #empty>
                  <div class="has-text-centered">No {{ $tc('Learner', 2) }}</div>
                </template>
                <b-table-column
                    centered

                    v-slot="props"
                    label="ID"
                    field="user.id"
                    sortable
                    width="40"
                    numeric
                >{{ props.row.id }}
                </b-table-column>

                <b-table-column
                    centered
                    cell-class="has-text-left"

                    v-slot="props"
                    sortable
                    field="user.last_name"
                    :label="`${$tc('Learner',1)} Surname`"
                >{{ props.row.last_name }}
                </b-table-column>
                <b-table-column
                    centered
                    cell-class="has-text-left"
                    v-slot="props"
                    sortable
                    field="user.first_name"
                    :label="`${$tc('Learner',1)} Name`"
                >{{ props.row.first_name }}
                </b-table-column>
                <b-table-column
                    centered
                    sortable
                    field="enrolment_status.name"
                    v-slot="props"
                    label="Status"
                >{{ props.row.enrolment_status.name }}
                </b-table-column>
                <b-table-column
                    centered
                    cell-class="py-0"
                    v-if="selected_extramural.requires_educator_comment || selected_extramural.requires_educator_checkpoints"
                    label="Comment/Checklist" v-slot="props">
                  <b-button :loading="loadingComments"
                            :disabled="!$store.getters['entities/user-permissions/find']('view report-extramural-comments')"
                            @click="handleCommentButtonClick(props.row.id,userComment(props.row.id))"
                            type="is-primary">
                    {{ comment_user_ids.includes(props.row.id) ? 'View/Edit' : 'Create' }}
                  </b-button>
                </b-table-column>


              </b-table>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>

import {mapState} from "vuex";
import Enrolment from "@/models/Enrolment";
import User from "@/models/User";
import EnrolmentStatus from "@/models/EnrolmentStatus";
import ReportExtramuralComment from "@/models/ReportExtramualComment";
import ReportExtramuralCommentsForm from "@/components/reports/ReportExtramuralCommentsForm";
// import ReportExtramuralAssessmentGrades from "@/models/ReportExtramuralAssessmentGrades";

export default {
  name: "ExtramuralComments",
  components: {},
  data() {
    return {
      loadingTerm: null,
      loadedTable: true,
      loaded: false,
      loadedEnrolmentsTable: false,
      search: null,
      loadingAssessments: [],
      focusedInput: {assessment_id: null, user_id: null},
      expandedTerm: null,
      expandedParentTerm: null,
      year: this.selected_assessment ? new Date(this.selected_assessment.start).getFullYear() : new Date().getFullYear(),
      filter_by_date: false,
      loadingFilter: false,
      plansDataHold: null,
      meta: Object,
      page: 1,
      use_modal: false,
      limit: 20,
      loading: false,
      loadingData: false,
      termsDataHold: null,
      term_id: null,
      focusedAssessment: this.selected_assessment,
      assessmentsDataHold: null,
      markGroupsDatahold: [],
      markGroupsEditHold: [],
      gradeThresholdsDatahold: [],
      gradeThresholdsEditHold: [],
      loadingGradeThresholds: false,
      sortField: null,
      sortOrder: null,
      loadingMarkGroups: false,
      searchHold: null,
      phaseSearchId: null,
      stageSearchId: null,
      loadingComments: false
    }
  },
  props: {
    report: {
      type: Object,
      required: true
    },
    selected_extramural: {
      type: Object,
      required: true
    },
    canDelete: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    headerText: {
      type: String,
      default() {
        return "Courses";
      },
    },
    createText: {
      type: String,
      default() {
        return "Course";
      },
    },
    tabEntity: {
      type: String,
      default() {
        return "Extramurals";
      },
    },

  },
  methods: {


    handleCommentButtonClick(user_id, extramuralComment) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          ...{
            userId: user_id,
            reportId: this.report.id,
            reportStatus: this.report.status_id < 7 ? 'open' : 'closed',
            edit: this.comment_user_ids.includes(user_id),
            inModal: true,
            reportExtramural: this.selected_extramural
          },
          ...(extramuralComment !== null ? {reportComment: extramuralComment} : {})
        },
        component: ReportExtramuralCommentsForm,
        fullScreen: false,
        trapFocus: true,
        canCancel: ['outside', 'x']
      });
    },


    userComment(user_id) {
      if (ReportExtramuralComment.query().where('user_id', user_id).exists()) {
        return ReportExtramuralComment.query().where('user_id', user_id).first()
      }
      return null
    },
    getComments() {
      this.loadingComments = true
      ReportExtramuralComment.FetchAll({
        page: 1,
        limit: 999
      }, {report_extramural_id: this.selected_extramural.report_extramural_id}, [], this.report.id).catch(err => {
        this.handleError(err)

      }).finally(() => {
        this.loadingComments = false
      })
    },

    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
    },


    selectTerms() {

      this.loading = true


      User.deleteAll()

      // return Enrolment.FetchEnrolmentsByExtramuralId(this.selected_extramural.id, ['user'], {
      //   page: 1,
      //   limit: 999
      // }, {
      //   year: this.report.year, ...(this.$store.state.campus.selected_class_group_id
      //       ? {
      //         class_group_id: this.$store.state.campus.selected_class_group_id,
      //       }
      //       : {})
      // }, 'create')
     return User.FetchReportExtramuralUsers({page:1,limit:999},[],this.report.id,this.selected_extramural.report_extramural_id)
          .then(() => {
            this.assessmentsDataHold = null

          }).then(() => {
        this.assessmentsDataHold = null

      }).finally(() => {
        this.loading = false
        this.loaded = true
        this.$store.dispatch('loader/hide')
        this.$nextTick(() => {
          this.loadedEnrolmentsTable = true

        })
      })


    },


  }
  ,

  computed: {
    users() {
      return User.query().with('enrolment_status').get()
    },
    show_data() {
      return this.selected_extramural.display_type_id !== 3 && this.selected_extramural.display_type_id !== 4
    },
    comment_user_ids() {
      let user_ids = []
      ReportExtramuralComment.query().get().map(comment => user_ids.push(comment.user_id))
      return user_ids
    },


    orderedEnrolments() {
      if (this.loaded) {
        return this.$refs.nameTable.newData
      }
      return []
    },
    enrolments() {
      return Enrolment.query().with('user').with('status').get()
    },


    ...mapState('campus', ["selected_campus_id", "all_campuses", "selected_class_group_id"]),


  }
  ,

  mounted() {
    this.$store.dispatch("loader/show");
    EnrolmentStatus.FetchAll()
    this.loaded = false
    this.loadedEnrolmentsTable = false
    User.deleteAll()
    ReportExtramuralComment.deleteAll()
    this.getComments()
    this.selectTerms()
    // Term.deleteAll()
    // Assessment.deleteAll()
    // User.deleteAll()
    // EnrolmentStatus.FetchAll()
    // ReportExtramuralGradeTotals.deleteAll()
    // ReportExtramuralComment.deleteAll()
    // this.getTotals()
    // this.getGradeThresholds()
    // this.getComments()
    // this.getTerms().then(() => {
    //   if (this.terms.length > 0) {
    //     this.selectTerms(this.report.term_ids)
    //
    //   }
    //
    // })


  }
  ,
}
;
</script>

<style lang="scss">


::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.bar-container {
  max-width: 99%;
  overflow-x: scroll;
  overflow-y: hidden;
}

.flippy {
  max-width: 100%;
}

.bar-container,
.flippy {
  transform: rotateX(180deg);
  -moz-transform: rotateX(180deg); /* Mozilla */
  -webkit-transform: rotateX(180deg); /* Safari and Chrome */
  -ms-transform: rotateX(180deg); /* IE 9+ */
  -o-transform: rotateX(180deg); /* Opera */
}
</style>
