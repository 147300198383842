<template>
  <form @submit.prevent="submit">
    <div :class="{'modal-card':inModal}" style="width: auto;">
      <header v-if="inModal" class="modal-card-head">
        <p class="modal-card-title">{{ edit === false ? 'Create a new' : 'Edit' }} Report Extramural Comment</p>
      </header>
      <section :class="{'modal-card-body':inModal}">


        <b-field v-if="loaded &&reportExtramural.requires_educator_comment" expanded label="Educator's Comment"
                 style="overflow: hidden">
          <ckeditor :editor="editor" v-model="reportCommentObject.comment" :config="editorConfig"></ckeditor>
        </b-field>

      </section>
      <footer v-if="inModal" :class="{'modal-card-foot':inModal}" class="has-text-centered">
        <b-field style="width: 100%" grouped position="is-centered">
          <b-field position="is-centered">
            <b-button
                :disabled="edit?(!$store.getters['entities/user-permissions/find']('edit report-extramural-comments')|| reportStatus==='closed'):(!$store.getters['entities/user-permissions/find']('create report-extramural-comments')|| reportStatus==='closed')"
                native-type="submit" type="is-primary" :icon-right="$tc(`icons.${edit===false?'create':'edit'}`)">Save
            </b-button>
          </b-field>
          <b-field v-if="edit" position="is-centered">
            <b-button
                :disabled="!$store.getters['entities/user-permissions/find']('delete report-extramural-comments') || reportStatus==='closed'"
                @click="startDelete()" type="is-danger" :icon-right="$tc(`icons.delete`)">Delete
            </b-button>
          </b-field>
        </b-field>
      </footer>
      <div class="pt-3" v-else>
        <b-field position="is-centered" grouped>
          <b-field position="is-centered">
            <b-button
                :disabled="edit?(!$store.getters['entities/user-permissions/find']('edit report-extramural-comments')|| reportStatus==='closed'):(!$store.getters['entities/user-permissions/find']('create report-extramural-comments')|| reportStatus==='closed')"
                native-type="submit" :icon-right="$tc(`icons.${edit===false?'create':'edit'}`)" type="is-primary">Save
            </b-button>
          </b-field>
          <b-field v-if="edit"
                   position="is-centered">
            <b-button
                :disabled="!$store.getters['entities/user-permissions/find']('delete report-extramural-comments') || reportStatus==='closed'"
                @click="startDelete()" type="is-danger" :icon-right="$tc(`icons.delete`)">Delete
            </b-button>
          </b-field>
        </b-field>
      </div>
    </div>
    <b-loading v-model="loading" :is-full-page="false" :can-cancel="false"></b-loading>
  </form>
</template>

<script>

import ReportExtramuralComment from "@/models/ReportExtramualComment";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import {editorConfig} from "@/editorConfig";

export default {
  name: "ReportExtramuralCommentsForm",
  data() {
    return {
      loading: false,
      loaded: false,
      editor: ClassicEditor,
      editorConfig: {
        ...editorConfig,
        ...{placeholder: "Educator's Comment"},
      },
      reportCommentObject: {
        report_extramural_id: this.reportExtramural.report_extramural_id,
        comment: null,
        user_id: this.userId,
      }
    }
  },

  methods: {
    startDelete() {
      this.$buefy.dialog.confirm({
        title: `Deleting extramural comment`,
        confirmText: `Delete extramural comment`,
        hasIcon: true,
        type: "is-danger",
        message: `Are you sure you want to delete this extramural comment?`,
        onConfirm: () =>
            ReportExtramuralComment.Delete(this.reportCommentObject)
                .then(() => {
                  this.$buefy.snackbar.open(`Report Extramural Comment deleted!`);
                  this.$emit('close')
                })
                .catch((err) => {
                  this.handleError(err)
                }),
      });
    },
    submit() {
      if (this.edit) {
        ReportExtramuralComment.Update({
              report_id: this.reportId,
              report_extramural_id: this.reportCommentObject.report_extramural_id,
              user_id: this.reportCommentObject.user_id,
              id: this.reportCommentObject.id,
              comment: this.reportCommentObject.comment,
              json_comments: this.reportCommentObject.json_comments,
            }
        ).then(() => {
          this.$buefy.snackbar.open(`Report Extramural Comment updated!`)
          this.$emit('close')
        }).catch(err => {
          this.handleError(err)
        })
      } else {
        ReportExtramuralComment.Store({
          report_id: this.reportId,
          report_extramural_id: this.reportCommentObject.report_extramural_id,
          user_id: this.reportCommentObject.user_id,
          comment: this.reportCommentObject.comment,
          json_comments: this.reportCommentObject.json_comments,
        }).then(() => {
          this.$buefy.snackbar.open(`Report Extramural Comment created!`)
          this.$emit('close')
        }).catch(err => {
          this.handleError(err)
        })
      }
    }
  }
  ,
  mounted() {
    if (this.edit) {
      this.reportCommentObject = JSON.parse(JSON.stringify(this.reportComment))


      this.loaded = true
    } else {
      // let checkpoints = []
      // this.reportExtramural.checkpoints.map(checkpointGroup => {
      //   let object = {title: checkpointGroup.title, options: []}
      //   checkpointGroup.options.map(option => {
      //     object.options.push({question: option.question, answer: null})
      //   })
      //   checkpoints.push(object)
      // })
      // this.reportCommentObject.json_comments = checkpoints
      this.loaded = true
    }
  }
  ,
  props: {
    userId: {
      type: Number,
      required: true
    }, reportId: {
      type: Number,
      required: true
    },

    reportExtramural:
        {
          type: Object,
          required: true

        }
    ,
    reportComment:
        {
          type: Object,
          required: false

        }
    ,
    inModal: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    ,
    edit: {
      type: Boolean,
      default
          () {
        return false
      }
    },
    reportStatus: {
      type: String,
      default
          () {
        return 'open'
      }
    }
    ,
  }
}
</script>

