<template>
  <div class="is-fullwidth">

      <div class="bar-container">
        <PerfectScrollbarWrapper>

        <div class="flippy">

              <b-table
                  class="specific-header-height is-fullwidth"
                  :data="users"
                  :striped="true"
                  :scrollable="false"
                  ref="name-table"
                  :loading="loading"
                  :hoverable="true"
                  :bordered="false"
              >
                <template #empty>
                  <div class="has-text-centered">No {{$tc('Learner',2)}}</div>
                </template>
                <b-table-column
                    centered
                    v-slot="props"
                    label="ID"
                    field="id"
                    sortable
                    width="40"
                    numeric
                >{{ props.row.id }}
                </b-table-column>

                <b-table-column
                    centered
                    cell-class="has-text-left"
                    v-slot="props"
                    sortable
                    field="last_name"
                    :label="`${$tc('Learner',1)} Surname`"
                >{{ props.row.last_name }}
                </b-table-column>
                <b-table-column
                    centered
                    cell-class="has-text-left"
                    v-slot="props"
                    sortable
                    field="first_name"
                    :label="`${$tc('Learner',1)} Name`"
                >{{ props.row.first_name }}
                </b-table-column>
                <b-table-column
                    centered
                    cell-class="py-0"
                    label="Comments" v-slot="props">
                  <b-button :loading="loadingComments"
                            :disabled="!$store.getters['entities/user-permissions/find']('view report-user-comments')"
                            @click="handleCommentButtonClick(props.row.id,userComment(props.row.id))"
                            type="is-primary">
                    {{ userComment(props.row.id) ? 'View/Edit' : 'Create' }}
                  </b-button>
                </b-table-column>
                <b-table-column
                    centered
                    cell-class="py-0"
                    label="Download" v-slot="props">
                  <b-dropdown append-to-body aria-role="list">
                    <template #trigger="{ active }">
                      <b-button
                          label="Actions"
                          type="is-primary"
                          :icon-right="active ? 'menu-up' : 'menu-down'"/>
                    </template>

                    <b-dropdown-item
                        v-if="!!$store.getters['entities/user-permissions/find']('download user report')"
                        @click="downloadPdf(props.row)"
                        aria-role="listitem">Download Report
                    </b-dropdown-item>
                    <b-dropdown-item @click="regenerate(props.row)"
                                     v-if="!!$store.getters['entities/user-permissions/find']('regenerate saved reports')"
                                     aria-role="listitem">Regenerate
                    </b-dropdown-item>

                  </b-dropdown>

                  <!--                  <b-field>-->
                  <!--                    <b-button :disabled="!$store.getters['entities/user-permissions/find']('download user report')"-->
                  <!--                              @click="downloadPdf(props.row)" type="is-primary"-->
                  <!--                    >Download Report-->
                  <!--                    </b-button-->
                  <!--                    >-->
                  <!--                  </b-field>-->
                </b-table-column>


              </b-table>


        </div>
        </PerfectScrollbarWrapper>

      </div>

  </div>


</template>

<script>

import {mapState} from "vuex";

import User from "@/models/User";
import ReportUserComment from "@/models/ReportUserComment";
import ReportUserCommentForm from "@/components/reports/ReportUserCommentForm";
import Report from "@/models/Report";
import HomeClass from "@/models/HomeClass";
import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";

export default {
  name: "ReportStudents",
  components: {
    PerfectScrollbarWrapper
  },
  data() {
    return {
      loading: false,
      loadingComments: false
    };
  },
  props: {

    report: {
      type: Object,
      required: true
    },

  },
  methods: {
    regenerate(user) {
      this.$buefy.dialog.confirm({
        title: "Regenerating report",
        message:
            "Are you sure you want to <b>regenerate</b> this report?",
        confirmText: "Regenerate Report",
        type: "is-link",
        hasIcon: true,
        onConfirm: () => this.regenerateReport(user),
      });
    },
    regenerateReport(user) {
      this.$store.dispatch("loader/show");
      Report.ReSaveSingle(this.report.id, user.id).then(
          () => {
            this.$buefy.snackbar.open('Report Regeneration Started!')
            this.$store.dispatch("loader/hide");
          }
      ).catch(
          err => {
            this.handleError(err)
          }
      )

    },
    downloadPdf(user) {
      this.$store.dispatch('loader/show')
      Report.DownloadUserReport(this.report.id, user.id).then(({response}) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
            "download",
            `${this.report.name} - ${user.first_name} ${user.last_name} report.pdf`
        );

        document.body.appendChild(link);
        link.click();
        this.$store.dispatch('loader/hide')

      }).catch((err) => {
        this.handleError(err)
        this.$store.dispatch('loader/hide')
      })
    },
    getUsers() {
      User.deleteAll()

      return User.FetchByReportId({
        page: 1,
        limit: 999
      },{
        ...(this.selected_home_class_id
            ? {
              home_class_id: this.selected_home_class_id,
            }
            : {})}, this.report.id).catch(err => {
        this.handleError(err)
      })
    },
    handleCommentButtonClick(user_id, reportUserComment) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          ...{
            reportStatus: this.report.status_id < 7 ? 'open' : 'closed',
            reportId: this.report.id,
            hasSummaryComment: this.report.has_summary_comment === 1,
            userId: user_id,
            edit: reportUserComment !== null,
            inModal: true,
          },
          ...(reportUserComment !== null ? {reportUserComment: reportUserComment} : {})
        },
        component: ReportUserCommentForm,
        fullScreen: false,
        trapFocus: true,
        canCancel: ['outside', 'x']
      });
    },


    userComment(user_id) {
      if (ReportUserComment.query().where('user_id', user_id).exists()) {
        return ReportUserComment.query().where('user_id', user_id).first()
      }
      return null
    },
    getComments() {
      this.loadingComments = true
      return ReportUserComment.FetchAll({
        page: 1,
        limit: 999
      }, {}, [], this.report.id).catch(err => {
        this.handleError(err)
      }).finally(() => {
        this.loadingComments = false
      })
    },
  },
  watch:{
    selected_home_class_id(){
      this.$store.dispatch('loader/show')
      this.getUsers().then(()=>{
        this.$store.dispatch('loader/hide')
      })
    }
  },
  computed: {
    comments(){
      return ReportUserComment.query().get()
    },
    users() {
      return User.query().get()
    },
    ...mapState('campus', ["selected_campus_id", "all_campuses","selected_home_class_id"]),
  }
  ,
  mounted() {

    this.$store.dispatch("loader/show");
    this.$store.state.documentation.tab_name = 'students'
    this.$store.state.documentation.active_doc_document_path = './Reports/Students.md'
    HomeClass.deleteAll()
    User.deleteAll()
    ReportUserComment.deleteAll()
    this.$store.dispatch('campus/selectHomeClass', null)


    Promise.all([this.getComments(), this.getUsers(), HomeClass.FetchAll({
      page: 1,
      limit: 999
    }, {campus_id: this.report.campus_id, phase_id: this.report.phase_id, year: this.report.year})]).finally(() => {
      this.$store.dispatch('loader/hide')
      this.$store.state.campus.loadedHomeClasses = true
    })

  }
  ,
}
;
</script>

<style lang="scss">


::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.bar-container {
  max-width: 99%;
  overflow-x: scroll;
  overflow-y: hidden;
}

.flippy {
  max-width: 100%;
}

.bar-container,
.flippy {
  transform: rotateX(180deg);
  -moz-transform: rotateX(180deg); /* Mozilla */
  -webkit-transform: rotateX(180deg); /* Safari and Chrome */
  -ms-transform: rotateX(180deg); /* IE 9+ */
  -o-transform: rotateX(180deg); /* Opera */
}
</style>
