<template>
  <div>
    <div class="columns">
      <div class="column">
        <b-field grouped>
          <b-field label="Focus Term" expanded>
            <b-select expanded placeholder="Focus Term"
                      v-model="term_id_filter">
              <option :value="null">None</option>
              <optgroup :label="term.name" v-for="term in filterTerms" :key="term.id">
                <option v-for="child in term.children" :value="child.id" :key="child.id">{{ child.name }}</option>
              </optgroup>
            </b-select>
          </b-field>
          <!--                    <b-field >-->
          <!--                      <b-numberinput placeholder="Year" v-model="year" min="2020" step="1">-->

          <!--                      </b-numberinput>-->
          <!--                    </b-field>-->
          <!--                    <b-field>-->
          <!--                      <b-button @click="clear()">-->
          <!--                        Clear-->
          <!--                      </b-button>-->
          <!--                    </b-field>-->
        </b-field>
      </div>
    </div>
    <hr>
    <PerfectScrollbarWrapper>
    <b-table
        class="margin-top is-clickable is-fullwidth"
        :data="reports"
        :loading="loadingData"
        :striped="true"
        :hoverable="true"
        :scrollable="false"
        @click="selectReport"
    >
      <template #empty>
        <div class="has-text-centered">No Reports</div>
      </template>
      <!--          :backend-sorting="true"-->
      <!--          :default-sort="['first_name', 'asc']"-->
      <!--          @sort="onSort"-->
      <b-table-column
          v-slot="props"
          label="ID"
          field="id"
          sortable
          width="40"
          numeric
      >{{ props.row.id }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="Name"
          field="name"
          sortable
      >{{ props.row.name }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="Focus Term"
      >{{ props.row.term ? props.row.term.name : '' }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="Terms"
      >
              <span v-for="(term,index) in props.row.terms" :key="index">{{
                  term.name
                }}{{ props.row.terms.length - 1 > index ? ',' : '' }} </span>
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="Year"
          field="year"
          sortable
      >{{ props.row.year }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="School Level"
          field="school_level"
          sortable
      >{{ props.row.school_level ? getSchoolLevelName(props.row.school_level) : '' }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="Template"
          field="template_id"
          sortable
      >{{ getTemplateName(props.row.template_id) }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="Phase"
          field="phase_id"
          sortable
      >{{ props.row.phase ? props.row.phase.name : '' }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="Start"
          field="start"
          sortable
      >{{ props.row.start }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="End"
          field="end"
          sortable
      >{{ props.row.end }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="Status"
          field="status_id"
          sortable

      >{{ getStatusName(props.row.status_id) }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="Download Ready"
          field="is_saved_to_users"
          sortable
          centered
      >
      <b-icon class="has-text-success" :icon="props.row.is_saved_to_users===1? $tc('icons.check'):$tc('icons.circle')"></b-icon>
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="Submitted Comments"
          field="report_user_comments_count"
          sortable

        >{{ props.row.report_user_comments_count }}/{{ props.row.users_count }}
        </b-table-column>
        <!--      <b-table-column-->
        <!--          v-slot="props"-->
        <!--          sortable-->
        <!--          field="course_scope.learning_stream.name"-->
        <!--          label="Learning Stream"-->
        <!--      >{{ props.row.course_scope.learning_stream.name }}-->
        <!--      </b-table-column>-->


      <b-table-column
          v-slot="props"
          label=""
          custom-key="actions"
          centered
          v-on:click.stop
      >
      <div v-on:click.stop>
        <b-dropdown position="is-bottom-left" append-to-body aria-role="list">
          <template #trigger>
            <b-icon
                :icon="$tc('icons.more')"/>
          </template>
          <b-dropdown-item @click="editReport(props.row)"
                           v-if="!!$store.getters['entities/user-permissions/find']('edit reports')"
                           aria-role="listitem">Edit
          </b-dropdown-item>
          <b-dropdown-item
              @click="download(props.row)"
              v-if="!!$store.getters['entities/user-permissions/find']('download all user reports')"
              aria-role="listitem">Download Reports
          </b-dropdown-item>
          <b-dropdown-item @click="regenerate(props.row)"
                           v-if="!!$store.getters['entities/user-permissions/find']('regenerate saved reports')"
                           aria-role="listitem">Regenerate
          </b-dropdown-item>
          <b-dropdown-item @click="startDeleteReport(props.row)"
                           v-if="!!$store.getters['entities/user-permissions/find']('delete reports')"
                           aria-role="listitem">Delete
          </b-dropdown-item>
        </b-dropdown>
      </div>
      </b-table-column>
    </b-table>
      </PerfectScrollbarWrapper>

      <b-pagination
        class="mt-4"
        :total="meta.total"
        :current="page"
        :range-before="2"
        :range-after="2"
        :per-page="limit"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        v-on:change="setPage"
    ></b-pagination>
  </div>

</template>

<script>
import Campus from "@/models/Campus";
import Phase from "@/models/Phase";
import Stage from "@/models/Stage";
import {mapState} from "vuex";
import Report from "@/models/Report";
import Term from "@/models/Term";
import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";

export default {
  name: "Reports.index",
  components: { PerfectScrollbarWrapper },
  data() {
    return {
      search: null,
      term_id_filter: null,
      loadingFilter: false,
      reportsDataHold: null,
      meta: Object,
      page: 1,
      use_modal: false,
      limit: 20,
      loadingData: false,
      searchHold: null,
      phaseSearchId: null,
      stageSearchId: null,
      year: new Date().getFullYear()
    };
  },
  props: {

    canDelete: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    headerText: {
      type: String,
      default() {
        return "Reports";
      },
    },
    createText: {
      type: String,
      default() {
        return "Report";
      },
    },
    tabEntity: {
      type: String,
      default() {
        return "Reports";
      },
    },

  },
  methods: {
    regenerate(report) {
      this.$buefy.dialog.confirm({
        title: "Regenerating report",
        message:
            "Are you sure you want to <b>regenerate</b> this report?",
        confirmText: "Regenerate Report",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.regenerateReport(report),
      });
    },
    download(report) {
      this.$store.dispatch('loader/show')
      Report.DownloadAllReports(report.id).then(({response}) => {
        const url = response.data.downloadUrl
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
            "download",
            `${report.name}.zip`
        );
        link.setAttribute(
            "target",
            `_blank`
        );

        document.body.appendChild(link);
        link.click();
        this.$store.dispatch('loader/hide')

      }).catch((err) => {
        this.handleError(err)
        this.$store.dispatch('loader/hide')
      })
    },

    getStatusName(id) {
      return this.$store.state.reports.statuses.find(type => type.id === id).name
    },
    getSchoolLevelName(id) {
      return this.$store.state.reports.school_levels.find(type => type.id === id).name
    }, getTemplateName(id) {
      return this.$store.state.reports.templates.find(type => type.id === id).name
    },
    startDeleteReport(report) {
      this.$buefy.dialog.confirm({
        title: "Deleting report",
        message:
            "Are you sure you want to <b>delete</b> this report? This action cannot be undone.",
        confirmText: "Delete Report",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteReport(report),
      });
    },
    deleteReport(report) {
      this.$store.dispatch("loader/show");
      Report.Delete(report.id).then(
          () => {
            this.$buefy.snackbar.open('Report deleted!')
            this.$store.dispatch("loader/hide");
          }
      ).catch(
          err => {
            this.handleError(err)
          }
      )

    }, regenerateReport(report) {
      this.$store.dispatch("loader/show");
      Report.ReSave(report.id).then(
          () => {
            this.$buefy.snackbar.open('Report Regeneration Started!')
            this.$store.dispatch("loader/hide");
          }
      ).catch(
          err => {
            this.handleError(err)
          }
      )

    },
    clear() {
      this.year = null
    },
    onSort(field, order) {

      this.order_by = field;
      this.order_direction = order;
    },

    selectReport(report) {
      this.$emit('selected', report)
    },
    editReport(report) {
      this.$emit('edit', report)
    },

    setPage(pageNumber, initial = false) {
      this.loadingData = true;
      this.reportsDataHold = this.courses;
      Report.deleteAll();
      this.page = pageNumber;
      Report.FetchAll(
          {
            page: this.page,
            limit: this.limit,
          },
          this.filters, ['terms', 'phase'],
          // ['report_user_comments', 'users']
      ).then(
          ({
             response: {
               data: {meta},
             },
           }) => {
            this.meta = meta;
            this.loadingData = false;
            this.reportsDataHold = null;
            if (!initial) {
              this.$cookies.set(
                  `report_filters`,
                  {
                    page: this.page,
                    phaseSearchId: this.phaseSearchId,
                    stageSearchId: this.stageSearchId,
                    year: this.year,
                    term_id_filter: this.term_id_filter
                  },
                  "1d"
              );
            }
          }
      );
    },
  },
  watch: {

    all_campuses(value) {
      if (value === true) {
        this.phaseSearchId = null
        this.stageSearchId = null
      }
    },
    selected_campus_id() {
      Term.FetchAll(
          {
            page: 1,
            limit: 99,
          },
          {year: this.$store.state.dates.year, is_parent: 1, campus_id: this.$store.state.campus.selected_campus_id},
          ["children"]
      )
      this.phaseSearchId = null
      this.stageSearchId = null
    },
    filters() {
      this.setPage(this.page);
    },
  },
  computed: {
    filterTerms() {
      return Term.query()
          .where('campus_id', this.$store.state.campus.selected_campus_id).where('parent_id', null).where(term => {
            return new Date(term.start).getFullYear() === this.$store.state.dates.year
          })
          .with('children').orderBy('start').get()

    },
    phase_filter: {
      set(val) {
        this.phaseSearchId = val
        this.stageSearchId = null
      }, get() {
        return this.phaseSearchId
      }
    },
    ...mapState('campus', ["selected_campus_id", "all_campuses"]),
    campuses() {
      return Campus.query().get();
    },
    phases() {
      return Phase.query().where((phase) => {
        return this.$store.state.campus.all_campuses ? true : phase.campus_id === this.$store.state.campus.selected_campus_id
      }).get()
    },
    stages() {
      return Stage.query().where((phase) => {
        return this.$store.state.campus.all_campuses ? true : phase.campus_id === this.$store.state.campus.selected_campus_id
      }).get()
    },
    filters() {
      return {
        ...(this.order_direction
            ? {
              order_direction: this.order_direction,
            }
            : {}), ...(this.order_by
            ? {
              order_by: this.order_by,
            }
            : {}),
        ...(this.phaseSearchId
            ? {
              phase_id: this.phaseSearchId,
            }
            : {})

        , ...(this.stageSearchId
            ? {
              stage_id: this.stageSearchId,
            }
            : {}), ...(this.term_id_filter
            ? {
              term_id: this.term_id_filter,
            }
            : {}),
        ...(this.$store.state.campus.selected_campus_id && !this.$store.state.campus.all_campuses
            ? {campus_id: this.$store.state.campus.selected_campus_id}
            : {}), year: this.$store.state.dates.year
      };
    },
    reports() {
      if (this.reportsDataHold === null) {
        return Report.query().with('phase').with('term').with('terms').get();
      } else {
        return this.reportsDataHold;
      }
    },

  },
  mounted() {
    Report.deleteAll()
    this.$store.dispatch("loader/show");
    if (this.$cookies.isKey(`report_filters`)) {
      let filters = this.$cookies.get(`report_filters`);
      this.page = filters.page;
      this.phaseSearchId = filters.phaseSearchId;
      this.stageSearchId = filters.stageSearchId
      this.order_by = filters.order_by
      this.order_direction = filters.order_direction
      this.term_id_filter = filters.term_id_filter
      this.$cookies.remove(`report_filters`);
    }

    Promise.all([Stage.FetchAll({
      page: 1,
      limit: 999
    }, {
      ...(this.$store.state.campus.selected_campus_id && !this.$store.state.campus.all_campuses
          ? {campus_id: this.$store.state.campus.selected_campus_id}
          : {}), year: this.$store.state.dates.year
    }, ['stages']), Term.FetchAll(
        {
          page: 1,
          limit: 99,
        },
        {year: this.$store.state.dates.year, is_parent: 1, campus_id: this.$store.state.campus.selected_campus_id},
        ["children"]
    )]).then(() => {
      this.$store.dispatch("loader/hide");
      this.setPage(this.page, true);
    }).catch(
        err => {
          this.$store.dispatch("loader/hide");

          if (err.response.status === 422) {
            this.handleError(err)
          } else {
            this.handleError(err)
          }
        }
    );
  },
};
</script>
