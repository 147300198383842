<template>
  <form @submit.prevent="submit">
    <div :class="{'modal-card':inModal}" style="width: auto;">
      <header v-if="inModal" class="modal-card-head">
        <p class="modal-card-title">{{ edit === false ? 'Create a new' : 'Edit' }} Report Subject Comment</p>
      </header>
      <section :class="{'modal-card-body':inModal}">


        <b-field v-if="loaded &&reportSubject.requires_educator_comment" expanded label="Educator's Comment"
                 style="overflow: hidden">
          <ckeditor :editor="editor" v-model="reportCommentObject.comment" :config="editorConfig"></ckeditor>
        </b-field>
        <div v-if="reportSubject.requires_educator_checkpoints && loaded">
          <div v-for="(checkpointGroup,index) in reportSubject.checkpoints" :key="index">
            <p class="is-size-4 has-text-weight-bold">{{ checkpointGroup.title }}</p>
            <b-field v-for="(practice,practiceIndex) in checkpointGroup.options" :key="practice.question" expanded
                     :label="practice.question">
              <b-select expanded required
                        v-model="reportCommentObject.json_comments[index].options[practiceIndex].answer">
                <option v-for="(answer,answerIndex) in practice.answers" :key="answerIndex" :value="answer">
                  {{ answer }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>

      </section>
      <footer v-if="inModal" :class="{'modal-card-foot':inModal}" class="has-text-centered">
        <b-field style="width: 100%" grouped position="is-centered">
          <b-field position="is-centered">
            <b-button
                :disabled="edit?(!$store.getters['entities/user-permissions/find']('edit report-subject-comments')|| reportStatus==='closed'):(!$store.getters['entities/user-permissions/find']('create report-subject-comments')|| reportStatus==='closed')"
                native-type="submit" type="is-primary" :icon-right="$tc(`icons.${edit===false?'create':'edit'}`)">Save
            </b-button>
          </b-field>
          <b-field v-if="edit" position="is-centered">
            <b-button
                :disabled="!$store.getters['entities/user-permissions/find']('delete report-subject-comments') || reportStatus==='closed'"
                @click="startDelete()" type="is-danger" :icon-right="$tc(`icons.delete`)">Delete
            </b-button>
          </b-field>
        </b-field>
      </footer>
      <div class="pt-3" v-else>
        <b-field position="is-centered" grouped>
          <b-field position="is-centered">
            <b-button
                :disabled="edit?(!$store.getters['entities/user-permissions/find']('edit report-subject-comments')|| reportStatus==='closed'):(!$store.getters['entities/user-permissions/find']('create report-subject-comments')|| reportStatus==='closed')"
                native-type="submit" :icon-right="$tc(`icons.${edit===false?'create':'edit'}`)" type="is-primary">Save
            </b-button>
          </b-field>
          <b-field v-if="edit"
                   position="is-centered">
            <b-button
                :disabled="!$store.getters['entities/user-permissions/find']('delete report-subject-comments') || reportStatus==='closed'"
                @click="startDelete()" type="is-danger" :icon-right="$tc(`icons.delete`)">Delete
            </b-button>
          </b-field>
        </b-field>
      </div>
    </div>
    <b-loading v-model="loading" :is-full-page="false" :can-cancel="false"></b-loading>
  </form>
</template>

<script>

import ReportSubjectComment from "@/models/ReportSubjectComment";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import {editorConfig} from "@/editorConfig";

export default {
  name: "ReportSubjectCommentsForm",
  data() {
    return {
      loading: false,
      loaded: false,
      editor: ClassicEditor,
      editorConfig: {
        ...editorConfig,
        ...{placeholder: "Educator's Comment"},
      },
      reportCommentObject: {
        report_subject_id: this.reportSubject.report_subject_id,
        comment: null,
        json_comments: {},
        user_id: this.userId,
      }
    }
  },

  methods: {
    startDelete() {
      this.$buefy.dialog.confirm({
        title: `Deleting subject comment`,
        confirmText: `Delete subject comment`,
        hasIcon: true,
        type: "is-danger",
        message: `Are you sure you want to delete this subject comment?`,
        onConfirm: () =>
            ReportSubjectComment.Delete(this.reportCommentObject)
                .then(() => {
                  this.$buefy.snackbar.open(`Report Subject Comment deleted!`);
                  this.$emit('close')
                })
                .catch((err) => {
                  this.handleError(err)
                }),
      });
    },
    submit() {
      if (this.edit) {
        ReportSubjectComment.Update({
              report_id: this.reportId,
              report_subject_id: this.reportCommentObject.report_subject_id,
              user_id: this.reportCommentObject.user_id,
              id: this.reportCommentObject.id,
              comment: this.reportCommentObject.comment,
              json_comments: this.reportCommentObject.json_comments,
            }
        ).then(() => {
          this.$buefy.snackbar.open(`Report Subject Comment updated!`)
          this.$emit('close')
        }).catch(err => {
          this.handleError(err)
        })
      } else {
        ReportSubjectComment.Store({
          report_id: this.reportId,
          report_subject_id: this.reportCommentObject.report_subject_id,
          user_id: this.reportCommentObject.user_id,
          comment: this.reportCommentObject.comment,
          json_comments: this.reportCommentObject.json_comments,
        }).then(() => {
          this.$buefy.snackbar.open(`Report Subject Comment created!`)
          this.$emit('close')
        }).catch(err => {
          this.handleError(err)
        })
      }
    }
  }
  ,
  mounted() {
    if (this.edit) {
      this.reportCommentObject = JSON.parse(JSON.stringify(this.reportComment))
      /**
       * Logic to check if there have been changes to the comment fields since the reportSubjectComment was created
       */

      /**
       * Check if there are pre-persisted comments. If not then create the comment object structure. This would occur if checkpoint comments were not required initially and then were.
       * */
      if (this.reportCommentObject.json_comments === {}) {
        let checkpoints = []
        this.reportSubject.checkpoints.map(checkpointGroup => {
          let object = {title: checkpointGroup.title, options: []}
          checkpointGroup.options.map(option => {
            object.options.push({question: option.question, answer: null})
          })
          checkpoints.push(object)
        })
        this.reportCommentObject.json_comments = checkpoints
      } else {
        /**
         * If they do exist then check if all checkpoint groups are present. The primary key for this checking is the title.
         * */
        this.reportSubject.checkpoints.map(checkpointGroup => {
          if (!this.reportCommentObject.json_comments.some(commentGroup => commentGroup.title === checkpointGroup.title)) {
            /**
             * If a checkpoint group is not already present then add it to the reportComment.
             * */
            let object = {title: checkpointGroup.title, options: []}
            checkpointGroup.options.map(option => {
              object.options.push({question: option.question, answer: null})
            })
            this.reportCommentObject.json_comments.push(object)
          }
        })
        /**
         * If a checkpoint group is present in the ReportSubjectComment but is not on the api then remove it
         * */
        let commentGroupsToDelete = []
        this.reportCommentObject.json_comments.map(commentGroup => {
          if (!this.reportSubject.checkpoints.some(checkpointGroup => commentGroup.title === checkpointGroup.title)) {
            /**
             * Push the name of the group to be removed to an array against which the filter will be run
             * */
            commentGroupsToDelete.push(commentGroup.title)
          }
        })
        /**
         * Filter the reportSubjectComment's comments based off the array
         * */
        this.reportCommentObject.json_comments = this.reportCommentObject.json_comments.filter(commentGroup => !commentGroupsToDelete.includes(commentGroup.title))

        /**
         * Perform checks and operations to make sure that all and only the questions on the api are present in the comment group
         * */
        this.reportSubject.checkpoints.map(checkpointGroup => {
          /**
           * Find the index of the comment that matches with the checkpoint group
           * */
          let commentGroupIndex = this.reportCommentObject.json_comments.findIndex(commentGroup => commentGroup.title === checkpointGroup.title)

          /**
           * Determine if any options in a comment group are not present on the api and if so add them to a remove list
           * */
          let optionsToRemove = []
          this.reportCommentObject.json_comments[commentGroupIndex].options.map(commentOption => {
            if (!checkpointGroup.options.some(option => option.question === commentOption.question)) {
              optionsToRemove.push(commentOption.question)
            }
          })
          /**
           * remove the options on the remove list
           * */
          this.reportCommentObject.json_comments[commentGroupIndex].options = this.reportCommentObject.json_comments[commentGroupIndex].options.filter(option => !optionsToRemove.includes(option.question))

          checkpointGroup.options.map(checkpointOption => {
            /**
             *Check to see if any questions from the api are not present in the reportSubjectComment comment group and if so add them
             * */
            if (!this.reportCommentObject.json_comments[commentGroupIndex].options.some(option => option.question === checkpointOption.question)) {
              this.reportCommentObject.json_comments[commentGroupIndex].options.push({
                question: checkpointOption.question,
                answer: null
              })
            }
          })
        })


      }
      this.loaded = true
    } else {
      let checkpoints = []
      this.reportSubject.checkpoints.map(checkpointGroup => {
        let object = {title: checkpointGroup.title, options: []}
        checkpointGroup.options.map(option => {
          object.options.push({question: option.question, answer: null})
        })
        checkpoints.push(object)
      })
      this.reportCommentObject.json_comments = checkpoints
      this.loaded = true
    }
  }
  ,
  props: {
    userId: {
      type: Number,
      required: true
    }, reportId: {
      type: Number,
      required: true
    },

    reportSubject:
        {
          type: Object,
          required: true

        }
    ,
    reportComment:
        {
          type: Object,
          required: false

        }
    ,
    inModal: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    ,
    edit: {
      type: Boolean,
      default
          () {
        return false
      }
    },
    reportStatus: {
      type: String,
      default
          () {
        return 'open'
      }
    }
    ,
  }
}
</script>

